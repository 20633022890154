/* eslint-disable */
<template>
  <div class="manage-booking">
    <AdminNavigation ref="navigation" indexProps="5" />
    <div class="top-panel no-sub-title">
      <Header />
      <div v-if="showContent" class="header-title-section">
        <div class="header-title d-flex">
          <h1>Booking {{ booking.id }} on Case {{ booking.caseReference }}</h1>
          <img v-if="booking.hasVideoLink == 1" class="ml-4" src="@/assets/images/video_black_icon.svg" />
        </div>
      </div>
      <div v-else class="header-title-section"><h5>Invalid Booking Information</h5></div>
    </div>
    <div class="panel no-sub-title">
      <AdminBookingsMenu :key="statusKey" :indexProps="isCancelled ? 7 : 0" />
      <div class="content" v-if="showContent">
        <div class="box case-container remove-min-height">
          <div class="box-heading tab d-flex align-items-center is-icon" @click="toggleBoxDisplay()">
            <img src="@/assets/images/case_dark_icon.svg" class="ml-2" height="50px" />
            <span class="mr-4">Claimant: {{booking.Salutation}} {{ booking.claimantFullName }}</span>
            <span class="mr-4">Client: {{ booking.clientName ? `${booking.clientName} - ${booking.branchName}` : ''}}</span>
            <span class="mr-4">
              <span v-if="getCaseOwnerName != ''" class="mr-2">{{ getCaseOwnerName }}</span>
              <span v-if="getCaseOwnerPhone1 != ''" class="mr-2"><b>P: </b>{{ getCaseOwnerPhone1 }}</span>
              <span v-if="getCaseOwnerMobile != ''" class="mr-2"><b>M: </b>{{ getCaseOwnerMobile }}</span>
              <span v-if="getCaseOwnerPhone2 != ''" class="mr-2"><b>P: </b>{{ getCaseOwnerPhone2 }}</span>
              <span v-if="getCaseOwnerEmail != ''" class="mr-2"><b>E: </b>{{ getCaseOwnerEmail }}</span>
            </span>
            <img src="@/assets/images/view_booking.svg" class="right-element is-icon" @click.stop="goToCase(booking.caseId)" />
          </div>
          <div class="box-body hidden-element">
            <div class="box-row">
              <div class="box-col box-col-left">Status</div>
              <div class="box-col box-col-right">
                <span class="">{{singleCase.caseStatus == 0 ? 'Inactive' : 'Active'}}</span>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Case Details</div>
              <div class="box-col box-col-right d-flex justify-content-between align-items-center">
                D.O.B: {{ formatDate(booking.dateOfBirth, "DD/MM/YYYY", "YYYY-MM-DD") }}
                <span>Type: {{ booking.claimTypeName }}</span>
                <span class="me-10">Reference number: {{ booking.caseReference }}</span>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Claimant Details</div>
              <div class="box-col box-col-right">
                <span v-if="booking.PreferredGivenName" class="mr-3"><b>Preferred Name: </b><span> {{ booking.PreferredGivenName }}</span></span>
                <span v-if="booking.claimantPhone1 != ''" class="mr-3"><b>Ph: </b><u class="is-icon" @click="openEmailModal('claimant', 1)">{{ booking.claimantPhone1 }}</u></span>
                <span v-if="booking.claimantMobile != ''" class="mr-3"><b>M: </b><u class="is-icon" @click="openEmailModal('claimant', 1)">{{ booking.claimantMobile }}</u></span>
                <span v-if="booking.claimantPhone2 != ''" class="mr-3"><b>Ph: </b><u class="is-icon" @click="openEmailModal('claimant', 1)">{{ booking.claimantPhone2 }}</u></span>
                <span v-if="booking.claimantEmail != ''" class="mr-3"><b>E: </b><u class="is-icon" @click="openEmailModal('claimant', 0)">{{ booking.claimantEmail }}</u></span>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Claimant Address</div>
              <div class="box-col box-col-right">{{ formattedAddress }}</div>
            </div>

            <!-- removed it form case section and added it to booking section -->
            <!-- <div class="box-row">
              <div class="box-col box-col-left left-label">Date of Accident</div>
              <div class="box-col box-col-right">{{formatDate(singleCase.accidentDate) }}</div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Affected Body Areas</div>
              <div class="box-col box-col-right">
                <div class="tag-div">
                  <vue-tags-input
                    :disabled="true"
                    v-model="bodyAreasTag"
                    :tags="bodyAreasTags"
                    :allow-edit-tags="false"
                    placeholder=""
                    :autocomplete-items="bodyAreasAutocomplete"
                    @tags-changed="(newTags) => (bodyAreasTags = newTags)"
                  />
                </div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Other Body Areas</div>
              <div class="box-col box-col-right">
                <div class="tag-div">
                  <vue-tags-input
                    :disabled="true"
                    v-model="otherBodyAreaTag"
                    :tags="otherBodyAreaTags"
                    placeholder=""
                    @tags-changed="(newTags) => (otherBodyAreaTags = newTags)" />
                </div>
              </div>
            </div>
            <div class="box-row">
              <div class="box-col box-col-left left-label">Conditions</div>
              <div class="box-col box-col-right">
                <div class="tag-div" :class="{ focused: focused }">
                  <vue-tags-input
                    :disabled="true"
                    v-model="conditionTag"
                    :tags="conditionTags"
                    :allow-edit-tags="false"
                    placeholder=""
                    :autocomplete-items="conditionsAutocomplete"
                    @tags-changed="(newTags) => (conditionTags = newTags)"
                  />
                </div>
              </div>
            </div> -->

            <div class="box-row">
              <div class="box-col box-col-left left-label">Client</div>
              <div class="box-col box-col-right">
                <span class="mr-3">Client: {{ booking.clientName }}</span>
                <span class="mr-3">{{ getCaseOwnerName }}</span>
                <span v-if="getCaseOwnerPhone1 != ''" class="mr-3"><b>P: </b><u class="is-icon" @click="openEmailModal('client', 1)">{{ getCaseOwnerPhone1 }}</u></span>
                <span v-if="getCaseOwnerMobile != ''" class="mr-3"><b>M: </b><u class="is-icon" @click="openEmailModal('client', 1)">{{ getCaseOwnerMobile }}</u></span>
                <span v-if="getCaseOwnerPhone2 != ''" class="mr-3"><b>P: </b><u class="is-icon" @click="openEmailModal('client', 1)">{{ getCaseOwnerPhone2 }}</u></span>
                <span v-if="getCaseOwnerEmail != ''" class="mr-3"><b>E: </b><u class="is-icon" @click="openEmailModal('client', 0)">{{ getCaseOwnerEmail }}</u></span>
              </div>
            </div>

            <!-- <div class="box-row">
              <div class="box-col box-col-left left-label">On Behalf Of</div>
              <div class="box-col box-col-right">
                <input :disabled="true" type="text" class="px-3" v-model="singleCase.onBehalfOf" placeholder="Enter on behalf of" />
              </div>
            </div> -->

          </div>
        </div>
        <div class="content-sub-content">
        <div class="row m-0 p-0">
          <div class="col-9 m-0 p-0">
            <div class="box">
              <div class="box-heading d-flex align-items-center">
                Booking Details
                <div class="right-element d-flex align-items-center">
                  <div v-if="booking.originaleBookingId" class="mr-5"><router-link class="ml-14" :to="{ name: 'admin-manage-booking', query: { id: booking.originaleBookingId } }"><v-btn dark>{{booking.originaleBookingId}}</v-btn></router-link></div>
                  <img @click="toEditPage" class="mt-1 mr-4 is-icon small-icon" src="@/assets/images/chat_icon_edit.svg" />
                  <img @click="openPreEmailModal()" class="mt-1 mr-2 is-icon small-icon" src="@/assets/images/chat_Icon_circle.svg" />
                  <a :href="booking.zohoLink" target="blank"><img src="@/assets/images/zoho_circle.svg" class="ml-2 mr-2 is-icon" /></a>
                  <!-- Removed private option from the booking -->
                  <!-- <div v-if="booking.private" class="ml-2 mr-2 is-icon" style="background: white; width: 40px; height: 40px; border-radius: 50%; border-radius: 50%; justify-content: center; display: flex;">
                    <img v-if="booking.private" src="@/assets/images/eye-private.svg" style="width: 20px;" />
                  </div> -->
                </div>
              </div>
              <div class="box-body d-block">
                <div class="box-row" :class="(isDisabled || booking.bookingStatus === 6 || disableBookingType) ? 'disabled-element' : ''">
                  <div class="box-col box-col-left left-label">Booking Type</div>
                  <div class="box-col box-col-right">
                    <select v-model="booking.bookingTypeId" class="px-3  w-75" @change="changeBookingType()"
                      :disabled="isDisabled || booking.bookingStatus === 6 || disableBookingType">
                      <option value="" hidden>Please choose ...</option>
                      <option :value="type.id" v-for="type in bookingTypes" :disabled="type.id === 6">{{ type.name }}</option>
                    </select>
                  </div>
                </div>
                <div v-if="booking.bookingTypeId == 4 || booking.bookingTypeId == 15" class="box-row">
                  <div class="box-col box-col-left left-label">Video Link URL</div>
                  <div class="box-col box-col-right">
                    <input :disabled="isDisabled || booking.bookingStatus === 6 || disableBookingType" type="text" maxlength="255" class="textbox video-link-url" v-model="booking.videoLinkUrl" placeholder="Video Link URL" />
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Booking Status</div>
                  <div v-if="booking.bookingStatus !== 6" class="box-col box-col-right box-center">
                    <select  v-model="booking.bookingStatus" @change="changeBookingStatusName()" class="px-3  w-75">
                      <option value="" hidden>Please choose ...</option>
                      <option :value="bookingStatus.id" v-for="bookingStatus in bookingStatuses">{{ bookingStatus.name }}</option>
                    </select>
                    <div class="ml-2">
                      {{booking.trackingStatus}}
                    </div>
                  </div>
                  <div v-else class="box-col box-col-right box-center px-8 w-75">{{booking.bookingStatusName}}</div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Specialist</div>
                  <div class="box-col box-col-right d-flex justify-content-between align-items-center">
                    <el-select filterable placeholder="Select a specialist" disabled class="px-3 w-50 mr-2" v-model="booking.specialistId">
                      <el-option :value="specialist.id" v-for="specialist in specialists" :label="specialist.fullName" :key="specialist.id">
                        {{ specialist.fullName }}
                      </el-option>
                    </el-select>
                    <el-tooltip  effect="dark" :content="curSpecialty" placement="bottom">
                    <span style="white-space: nowrap; display: block;  overflow: hidden;text-overflow: ellipsis; flex: 1">{{ curSpecialty }}</span>
                    </el-tooltip>
                    <router-link style="margin: unset" class="mr-2 ml-2" :to="{ name: 'admin-availability-bookings', query: { id: booking.specialistId, date: bookingDate } }"
                      ><img src="@/assets/images/calendar_black_icon.svg"
                    /></router-link>
                  </div>
                </div>
                <div class="box-row" v-if="isShowForBooking">
                  <div class="box-col box-col-left left-label">Location</div>
                  <div class="box-col box-col-right" style="display: flex;align-items: center;">
                    <div v-if="!locationFocus && booking.locationId == ''" @click="locationFocus = true">
                      <input type="text" maxlength="255" disabled placeholder="Click here to select location" />
                    </div>
                    <select disabled class="w-100" v-model="booking.locationId" v-else>
                      <option value="">Please Select ...</option>
                      <option v-for="location in locations" :value="location.id">{{ location.fullLocation }}</option>
                    </select>
                    <router-link class="ml-14" :to="{ name: 'admin-manage-location', query: { locationId: booking.locationId } }"><img src="@/assets/images/view_booking.svg"/></router-link>
                  </div>
                </div>
                <div v-if="showLinkLocation" class="box-row">
                  <div class="box-col box-col-left left-label">Examinee Location for VideoLink</div>
                  <div class="box-col box-col-right">
                    <input :disabled="booking.bookingStatus === 6"  type="text" class="px-3" maxlength="255" v-model="booking.ClaimantLocation" placeholder="Examinee Location for VideoLink" />
                  </div>
                </div>
                <div v-if="booking.bookingTypeId == 4 || booking.bookingTypeId == 7 || booking.bookingTypeId == 15" class="box-row">
                  <div class="box-col box-col-left left-label required">Claimant State During Booking</div>
                  <div class="box-col box-col-right">
                    <select :disabled="booking.bookingStatus === 6"  class="textbox" style="width: 30%" v-model="booking.claimantStateDuringBooking" :class="hasError && !validateState ? 'is-invalid' : ''">
                      <option value="" hidden>Please Select ...</option>
                      <option v-for="state in states" :value="state.value">{{ state.text }}</option>
                    </select>
                    <span class="pl-4 left-label">Booking Time in Claimant State</span>
                    <span type="text" class="w-35 ml-4">{{formatDate(booking.ClaimantBookingDateTime, "DD/MM/YYYY hh:mm A", "YYYY-MM-DD HH:mm:ss.ssss")}}</span>
                    <div v-if="hasError && !validateState" class="text-danger pl-2">State is required.</div>
                  </div>
                </div>
                <div class="box-row" v-if="isShowForBooking">
                  <div class="box-col box-col-left left-label">Date & Time</div>
                  <div class="box-col box-col-right" :key="timeKey">
                    <!-- <input type="text" v-model="bookingDateTimeComputed" /> -->
                    <datepicker disabled v-model="bookingDate" format="dd/MM/yyyy" :typeable="true" class="w-35" placeholder="dd/mm/yyyy"></datepicker>
                    <!-- <vue-timepicker v-if="!showTime" :disabled="showTime ? false : true" tabindex="14" format="hh:mm A" v-model="bookingTime" input-class="form-control w-50 border-0" class="w-50" placeholder="Appointment Time" :minute-interval="5" /> -->
                    <span type="text" class="w-35 ml-4">{{bookingTime}}</span>
                    <s class="ml-15 text-decoration-none">Length: {{ calculateBookingDuration(formatDate(booking.bookingDateTime, "YYYY-MM-DD", "YYYY-MM-DD HH:mm:ss"),formatDate(booking.bookingDateTime, "hh:mm A", "YYYY-MM-DD HH:mm:ss"),formatDate(booking.bookingEndTime, "hh:mm A", "HH:mm:ss"))}} minutes</s>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Booking Details</div>
                  <div class="box-col box-col-right d-flex align-items-center">
                    <img
                      class="ml-2 is-icon"
                      src="@/assets/images/claimant_attendance_icon.svg"
                      data-toggle="tooltip"
                      data-placement="right"
                      :title="getAttendanceTitle"
                      :data-original-title="getAttendanceTitle"
                      @click="toggleAttendance()"
                      :class="isDate(booking.claimantConfirmed) || booking.claimantConfirmed ? '' : 'disabled-element'"
                    />
                    <img
                      class="ml-4 is-icon"
                      src="@/assets/images/otfce_icon.svg"
                      data-toggle="tooltip"
                      data-placement="right"
                      :title="getOTFCETitle"
                      :data-original-title="getOTFCETitle"
                      @click="toggleOTFCE()"
                      :class="booking.otfceRequired ? '' : 'disabled-element'"
                    />
                    <img
                      class="ml-2 is-icon"
                      src="@/assets/images/can_ring_claimant_icon.svg"
                      style="padding-left: 6px;"
                      data-toggle="tooltip"
                      data-placement="right"
                      :title="getCanRingClaimantTitle"
                      :data-original-title="getCanRingClaimantTitle"
                      @click="toggleCanRingClaimant()"
                      :class="booking.redHealthMayContactClaimant ? '' : 'disabled-element'"
                    />
                    <img
                      class="ml-2 is-icon"
                      src="@/assets/images/consent_received_icon.svg"
                      data-toggle="tooltip"
                      data-placement="right"
                      :title="getConsentReceivedTitle"
                      :data-original-title="getConsentReceivedTitle"
                      @click="toggleConsentReceived()"
                      :class="isDate(booking.claimantConsentReceived) || booking.claimantConsentReceived ? '' : 'disabled-element'"
                    />
                    <img
                      class="is-icon"
                      src="@/assets/images/call_after_appointment_icon.svg"
                      data-toggle="tooltip"
                      data-placement="right"
                      style="padding-left: 6px;"
                      :title="getCallAfterAppointmentTitle"
                      :data-original-title="getCallAfterAppointmentTitle"
                      @click="toggleCallAfterAppointment()"
                      :class="booking.callClientAfterAppointment ? '' : 'disabled-element'"
                    />
                    <img
                      v-if="booking.hasVideoLink"
                      class="ml-4 is-icon"
                      src="@/assets/images/video_link_test_arranged_icon.svg"
                      data-toggle="tooltip"
                      data-placement="right"
                      :title="getVideoLinkTestArrangedTitle"
                      :data-original-title="getVideoLinkTestArrangedTitle"
                      @click="toggleVideoLinkTestArranged()"
                      :class="booking.videoLinkTestStatus == 1 || booking.videoLinkTestStatus == 2 ? '' : 'disabled-element'"
                    />
                    <img
                      v-if="booking.hasVideoLink"
                      class="ml-2 is-icon"
                      src="@/assets/images/video_link_test_icon.svg"
                      data-toggle="tooltip"
                      data-placement="right"
                      style="padding-left: 6px;"
                      :title="getHasVideoTitle"
                      :data-original-title="getHasVideoTitle"
                      @click="toggleHasVideo()"
                      :class="booking.videoLinkTestStatus == 2 ? '' : 'disabled-element'"
                    />
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">On behalf of</div>
                  <div class="box-col box-col-right">
                    <el-select :disabled="booking.bookingStatus === 6" v-model="booking.onBehalfOf" class="w-100" placeholder="On behalf of" filterable clearable>
                      <el-option v-for="item in clients" :key="item.id" :value="item.fullName" :label="item.fullName"></el-option>
                    </el-select>
                  </div>
                </div>

                <div class="box-row">
                  <div class="box-col box-col-left left-label">Date of Accident/Event</div>
                  <div class="box-col box-col-right box-col-center">
                    <div class="w-72">
                      <div class="right-float-element" style="line-height: 40px;">
                        <toggle-button :disabled="booking.bookingStatus === 6"  @change="changeOverTime()" class="togglePeriodOfTime" :color="{checked: '#56C568', unchecked: '#525252'}" :width="40" :sync="true" :value="booking.overPeriodofTime"/>  Over a period of time
                      </div>
<!--                      <div v-if="booking.overPeriodofTime || booking.bookingStatus === 6" class="w-50 accient-date" readonly placeholder="dd/MM/yyyy" v-model="booking.accidentDate" />-->
                      <datepicker
                          :disabled="booking.overPeriodofTime || booking.bookingStatus === 6"
                          format="dd/MM/yyyy"
                          :typeable="true"
                          class="w-50 accient-date"
                          placeholder="dd/mm/yyyy"
                          v-model="booking.overPeriodofTime ? '' : booking.accidentDate"
                          @selected="selectAccientDate"
                          @input="changeAccientDate"></datepicker>
                    </div>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Affected Body Areas</div>
                  <div class="box-col box-col-right box-col-center">
                    <vue-tags-input
                        :disabled="booking.bookingStatus === 6"
                        class="px-3 pr-7"
                      v-model="bodyAreaTag"
                      :tags="bodyAreaTags"
                      placeholder="Please choose"
                      @before-deleting-tag="deleteBodyArea"
                      @focus="openDigram" />
                    <div @click="openBodyDiagram">
                      <img src="@/assets/images/choose.svg" alt="" class="right-element align-self-center is-icon pr-3" style="top:50%;margin-top:-18px;"/>
                    </div>
                    <AddBodyDiagramModal style="top: 0px" ref="addBodyDiagramModal" />
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Other Body Areas</div>
                  <div class="box-col box-col-right box-col-center">
                    <vue-tags-input
                        :disabled="booking.bookingStatus === 6"
                        class="px-3"
                      :maxlength="50"
                      v-model="otherBodyAreaTag"
                      :tags="otherBodyAreaTags"
                      placeholder="Add Other Body Areas"
                      @tags-changed="updateOtherBodyArea" />
                  </div>
                </div>
                <div class="box-row reset-line-height">
                  <div class="box-col box-col-left left-label">Conditions</div>
                  <div class="box-col box-col-right box-col-center">
                    <vue-tags-input
                        :disabled="booking.bookingStatus === 6"
                        class="px-3"
                      ref="conditions"
                      v-model="personConditionTag"
                      :tags="personConditionTags"
                      placeholder="Add Conditions"
                      :add-only-from-autocomplete="true"
                      @before-deleting-tag="deletePersonCondition"
                      :autocomplete-items="filteredPersonConditionsItems"
                      @tags-changed="updateConditions" />
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Interpreter Details</div>
                  <div class="box-col box-col-right d-flex align-items-center">
                    <input :disabled="booking.bookingStatus === 6" type="text" class="px-3" maxlength="255" v-model="interpreterDetails" placeholder="Click to add interpreter details if required" />
                    <el-checkbox :disabled="booking.bookingStatus === 6" class="ml-3" v-model="booking.OrganizeRequired" size="large" >Red Health to organize</el-checkbox>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Surrogate Details</div>
                  <div class="box-col box-col-right">
                    <input :disabled="booking.bookingStatus === 6"  type="text" class="px-3" maxlength="255" v-model="surrogateDetails" placeholder="Click to add surrogate details if required" />
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Paperwork Due</div>
                  <div class="box-col box-col-right" style="padding-left:20px; display: flex;flex-direction: row;align-items: center;">
                    <div class="w-72">
                      <datepicker
                        v-model="booking.paperworkDue"
                        format="dd/MM/yyyy"
                        :typeable="true"
                        :clear-button="booking.bookingStatus === 6 ? false : true"
                        placeholder="dd/mm/yyyy"
                        :input-class="hasError && !isValidPaperworkDue ? 'is-invalid' : ''"
                        class="w-50 paperwork-due"
                        @input="changePaperworkDue"
                        :disabled="booking.bookingStatus === 6"
                        @cleared="clearPaperworkDue">
                      </datepicker>
                      <div class="text-danger ml-2" v-if="hasError && !isValidPaperworkDue">
                        Paperwork Due is invalid
                      </div>
                    </div>
                    <!-- <select
                      v-model="booking.paperworkStatus"
                      class="w-35 ml-4 custom-arrow ">
                      <option value="" hidden>Please choose ...</option>
                      <option value="1">LOI Received</option>
                      <option value="2">Brief Received</option>
                      <option value="3">Paperwork Processed</option>
                      <option value="4">Pre-Appointment Forms Completed</option>
                    </select> -->
                    <div class="box-col box-col-right d-flex align-items-center">
                      <img
                        class="ml-5 is-icon"
                        src="@/assets/images/lol-received.svg"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="LOI Received"
                        @click="togglelolReceived()"
                        :class="isDate(booking.lolReceived) ? '' : 'disabled-element'"
                      />
                      <img
                        class="ml-6 is-icon"
                        src="@/assets/images/brief-received.svg"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Brief Received"
                        @click="togglelolBrirgReceived()"
                        :class="isDate(booking.briefReceived) ? '' : 'disabled-element'"
                      />
                      <img
                        class="ml-6 is-icon"
                        src="@/assets/images/additional-paperwork-received.svg"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Additional Paperwork Received"
                        @click="togglelolAdditionalReceived"
                        :class="isDate(booking.additionalPaperworkReceived) ? '' : 'disabled-element'"
                      />

                      <img
                        class="ml-6 is-icon"
                        src="@/assets/images/paperwork-processed.svg"
                        data-toggle="tooltip"
                        data-placement="right"
                        title="Paperwork Processed"
                        @click="togglelolPaperworkProcessed"
                        :class="isDate(booking.paperworkProcessed) ? '' : 'disabled-element'"
                      />
                    </div>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Send Report To</div>
                  <div class="box-col box-col-right">
                    <!-- <select class="w-75" v-model="booking.reportTo">
                      <option value="" hidden>Please choose ...</option>
                      <option :value="contact.clientContactId" v-for="contact in client.contacts">{{ contact.fullName }}</option>
                      <option v-if="showOther" value="-1">Other</option>
                    </select> -->
                    <el-select
                        ref="dropdownEmailSendTo"
                        class="report-to-selecter select-dropdown w-100"
                        :options="listContact"
                        v-model="sendToModel"
                        :disabled="booking.bookingStatus === 6"
                        @change="selectedSendTo"
                        value-key="id"
                        filterable
                        clearable
                        placeholder="Select a Client">
                      <el-option v-for="item in listContact" :key="item.id" :value="item" :label="item.name"></el-option>
                    </el-select>
                  </div>
                </div>
                <div v-if="showSendReportToOther" class="box-row" >
                  <div  class="box-col box-col-left left-label">Send report Email</div>
                  <div class="box-col box-col-right">
                    <input :disabled="booking.bookingStatus === 6"  class="textbox" type="text" maxlength="100" placeholder="Send report to this email address" v-model="booking.reportTo"/>
                    <div v-if="hasError && !validateSendEmail" class="text-danger">Email is incorrect.</div>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Payment Details</div>
                  <div class="box-col box-col-right d-flex align-items-center">
                    <img
                      v-if="client.paymentDeferralOption"
                      class="is-icon"
                      src="@/assets/images/payment_deferral_icon.svg"
                      data-toggle="tooltip"
                      data-placement="right"
                      :title="getFeeDeferralRequiredTitle"
                      :data-original-title="getFeeDeferralRequiredTitle"
                      @click="toggleFeeDeferralRequired()"
                      :class="booking.feeDeferralRequired ? '' : 'disabled-element'"
                    />
                    <img
                      class="ml-2"
                      src="@/assets/images/prepayment_required_icon.svg"
                      data-toggle="tooltip"
                      data-placement="right"
                      :title="getPrePaymentRequiredTitle"
                      :data-original-title="getPrePaymentRequiredTitle"
                      :class="booking.prepaymentRequired ? '' : 'disabled-element'"
                    />
                    <!-- <img class="ml-4" src="@/assets/images/finance_black_icon.svg" @click="togglePaid()" :class="booking.paid == true ? '' : 'disabled-element'" /> -->
                    <img
                      class="ml-4 is-icon"
                      src="@/assets/images/specialist_black_icon.svg"
                      data-toggle="tooltip"
                      data-placement="right"
                      :title="getSpecialistConfirmedHoursTitle"
                      :data-original-title="getSpecialistConfirmedHoursTitle"
                      @click="toggleSpecialistConfirmedHours()"
                      :class="booking.specialistConfirmedHours ? '' : 'disabled-element'"
                    />
                    <img v-if="user.type == 4 || user.type == 6 && !this.hidePurchaseOrderButton" @click="goToPurchaseOrder()" class="right-element is-icon" src="@/assets/images/view_booking.svg"/>
                  </div>
                </div>
                <div class="box-row">
                  <div class="box-col box-col-left left-label">Bill To</div>
                  <div class="box-col box-col-right">
                    <!-- <select class="w-75 px-2" v-model="booking.billTo">
                      <option value="" hidden>Please choose ...</option>
                      <option :value="contact.clientContactId" v-for="contact in client.contacts">{{ contact.fullName }}</option>
                    </select> -->
                    <el-select
                      ref="dropdownEmailBillTo"
                      class="email-to-selecter select-dropdown w-100"
                      v-model="billToModel"
                      :disabled="booking.bookingStatus === 6"
                      @change="selectedBillTo"
                      value-key="id"
                      filterable
                      clearable
                      placeholder="Select a Client">
                      <el-option v-for="item in listContact" :key="item.id" :value="item" :label="item.name"></el-option>
                    </el-select>
                  </div>
                </div>
                <div v-if="showBillToEmail" class="box-row">
                  <div class="box-col box-col-left left-label">Bill to Email</div>
                  <div class="box-col box-col-right">
                    <input :disabled="booking.bookingStatus === 6"  class="textbox" type="text" placeholder="Bill to this email address" v-model="booking.billTo"/>
                    <div v-if="hasError && !validateBillEmail" class="text-danger">Email is incorrect.</div>
                  </div>
                </div>

                <div class="box-row" v-for="(hour, index) in preapprovedHours" :key="additionalTime + 'a' + index">
                  <div class="box-col box-col-left left-label">{{ index == 0 ? 'Pre-Approved Hours' : '' }}</div>
                  <div class="box-col box-col-right d-flex align-items-center ml-2">
                    {{ hour.service }}: {{ hour.numberOfHours + (hour.numberOfHours == 1 ? ' Hour' : ' Hours') }}
                    <div class="right-element">
                      <v-btn :disabled="booking.bookingStatus === 6" class="add-icon add-black-icon" style="background-color:#C4C4C4;color:#6686B6F" @click="openServiceTimeModalPreApproved" v-if="index == 0">Add New</v-btn>
                    </div>
                  </div>
                </div>
                <div class="box-row" v-if="preapprovedHours.length == 0">
                  <div class="box-col box-col-left left-label">Pre-Approved Hours</div>
                  <div class="box-col box-col-right d-flex align-items-center">
                    <div class="right-element">
                      <v-btn :disabled="booking.bookingStatus === 6"  class="add-icon add-black-icon" style="background-color:#C4C4C4;color:#6686B6F" @click="openServiceTimeModalPreApproved">Add New</v-btn>
                    </div>
                  </div>
                </div>
                <div class="box-row" v-for="(hour, index) in additionalHours" :key="additionalTime + index">
                  <div class="box-col box-col-left left-label">{{ index == 0 ? 'Additional Time' : '' }}</div>
                  <div class="box-col box-col-right d-flex align-items-center ml-2">
                    {{ hour.service }}: {{ hour.numberOfHours + (hour.numberOfHours == 1 ? ' Hour' : ' Hours') }}
                    <div class="right-element">
                      <v-btn :disabled="booking.bookingStatus === 6"  class="add-icon add-black-icon" style="background-color:#C4C4C4;color:#6686B6F" @click="openServiceTimeModal" v-if="index == 0">Add New</v-btn>
                    </div>
                    <el-tooltip :content="hour.reason">
                      <div class="ml-2 mr-2 text-overflow-hide">
                        {{hour.reason}}
                      </div>
                    </el-tooltip>
                    <div style="position: absolute; left: 50%; width: 150px">
                      <select :disabled="booking.bookingStatus === 6"  v-if="hour.status != 1" @change="updateStatus($event, index)" v-model="hour.status">
                        <option v-for="status in additionalHoursStatus" :value="status.id">{{ status.name }}</option>
                      </select>
                      <span v-else class="pl-2">Approved</span>
                    </div>
                  </div>

                </div>
                <div class="box-row" v-if="additionalHours.length == 0">
                  <div class="box-col box-col-left left-label">Additional Time</div>
                  <div class="box-col box-col-right d-flex align-items-center">
                    <div class="right-element">
                      <v-btn :disabled="booking.bookingStatus === 6"  class="add-icon add-black-icon" style="background-color:#C4C4C4;color:#6686B6F" @click="openServiceTimeModal">Add New</v-btn>
                    </div>
                  </div>
                </div>
                <AdminAddServiceTimeModal ref="adminAddServiceTimeModal" :bookingProps="booking" :roleProps="roleProps" />
                <div class="box-row">
                  <div v-if="user.type >= 3" class="box-col" style="line-height: 1.5; padding: 0.5rem 20px; font-weight: 500">
                    Allocated Admin: {{booking.ZohoAdminOwner}}
<!--                    <el-select :loading="loadingUser" clearable filterable remote :remote-method="remoteUser" placeholder="Allocated Admin" class="w-100" v-model="booking.assignedTo">-->
<!--                      <el-option v-for="item in users" :key="item.id" :value="item.id" :label="item.name">{{ item.name }}</el-option>-->
<!--                    </el-select>-->
                  </div>
                  <div class="box-col d-flex align-items-center justify-content-end w-100 p-2 flex-wrap">
                    <v-btn class="btn-light m-1" v-if="!hideButton && showSupplementaryService && booking.bookingStatus !== 6" @click="tapSupplementaryService()">Supplementary Service Request</v-btn>
                    <v-btn dark class="m-1" v-if="!hideButton && booking.bookingStatus !== 6" @click="tapCancel">Cancel Booking</v-btn>
                    <v-btn class="btn-light m-1" v-if="!hideButton && booking.bookingStatus !== 6" @click="requestAction()">Request Action</v-btn>
                    <v-btn class="btn-light m-1" v-if="hideButton" @click="tapDelete()">Delete</v-btn>
                    <v-btn class="m-1" :class="!enableButton || !hasChange ? 'disabled-element' : ''" dark @click="updateBooking()">Update</v-btn>
                  </div>
                </div>
                <RequestActionModal ref="requestActionModal" />
              </div>
            </div>
          </div>
          <div class="col-3 action-log d-inline-block m-0 p-0" style="vertical-align:top; height: 1000px;">
            <div style="display: flex;flex-direction: row;">
              <span style="line-height: 3; padding-left: 20%">Booking Log</span>
              <select @change="updateBookingLog($event)" class="ml-3 small-selector">
                <option value="0">Recent</option>
                <option value="1">View All</option>
              </select>
            </div>
            <Timeline :timeline="timelineData" />
          </div>
        </div>
        <div class="alert alert-success hidden-element">
          <a class="close" title="close">×</a>
          <b>Success:</b> {{ successMessage }}
        </div>
        <div class="alert alert-danger general-alert-danger hidden-element">
          <a class="close" title="close">×</a>
          <b>Error:</b> {{ errorMessage }}
        </div>
        <div class="alert alert-danger alert-warning-no-confirm hidden-element">
          <a class="close" title="close">×</a>
          <b>Error:</b> {{ errorMessage }}
        </div>
        <div class="alert alert-danger alert-warning-confirm  hidden-element">
          <div class="icon-group center-element">
            <v-btn class="alert-button" @click="tapCancelConfirmation(true)">Ok</v-btn>
          </div>
          <b>Error:</b> {{errorMessage}}
        </div>

        <div class="box remove-min-height">
<!--          <div class="box-heading d-flex align-items-center">-->
<!--            Tests Ordered-->
<!--            <div class="right-element">-->
<!--              <v-btn class="add-icon" :disabled="booking.bookingStatus === 6" dark @click="openTestsModal">Add New</v-btn>-->
<!--              <AddSpecialistBookingTestModal ref="addSpecialistBookingTestModal" :bookingProps="booking" :testsProps="test" />-->
<!--            </div>-->
<!--          </div>-->

          <div v-if="booking.bookingTests.length > 0" v-for="(bookingTest, index) in booking.bookingTests">
            <div class="box-body">
              <div class="box-row">
                <div class="box-col col">{{ bookingTest.testName }} </div>
                <div class="box-col col">{{ bookingTest.orderedFrom }}</div>
                <div class="box-col col">
                  <div class="left-label" v-if="bookingTest.dateOrdered != ''">Ordered: {{ bookingTest.dateOrdered != 'Invalid date' ? bookingTest.dateOrdered : '' }}</div>
                </div>
                <div class="box-col col">
                  <div class="left-label" v-if="bookingTest.dateReceived != ''">Received: {{ bookingTest.dateReceived != 'Invalid date' ? bookingTest.dateReceived : ''}}</div>
                </div>
                <div class="box-col col center-title">
                  <img v-if="booking.bookingStatus !== 6" class="right-align is-icon" src="@/assets/images/edit_icon.svg" @click="setTestAndOpenModal($event, bookingTest)" />
                </div>
              </div>
            </div>
          </div>
          <div v-else class="box-row"></div>
        </div>

        <div class="box">
          <div class="box-heading tab">
            <div class="tab-heading tab-selected px-5" id="heading-notes" @click="openTab(0)">Notes</div>
            <div class="tab-heading px-5" id="heading-files" @click="openTab(1)">Files</div>
            <div class="tab-heading px-5" id="heading-billing" @click="openTab(2)">Billing Notes</div>
            <div class="tab-heading px-5" id="heading-logs" @click="openTab(3)">Comms Log</div>
            <div class="tab-heading px-5" id="heading-specialist" @click="openTab(4)">Specialist Communication</div>
            <div class="right-element">
              <v-btn v-if="curTab === 1 && booking.bookingFiles.filter(item => item.checked).length > 0" class="download-icon mr-2" dark @click="downloadFiles">Download</v-btn>
              <v-btn v-if="curTab !== 3" class="add-icon" dark @click="openAddNew">Add New</v-btn>
              <AddNoteModal ref="addNoteModal" :isBilling="isBilling" :bookingProps="booking" fromProps="admin" />
            </div>
          </div>
          <div class="box-body tab-content tab-content-boxes hidden-element">
            <div class="box-row" v-for="(item, index) in bookingNoteAndActions" :key="index">
              <div class="box-col box-col-left" style="width: 12%">{{item.userName}}</div>
              <div class="box-col box-col-left" style="width: 13%">
                <!-- {{item.noteDateTime}} -->
                <p v-if="item.deleted">
                  <del>{{ formatDate(item.dateTime, "DD/MM/YYYY hh:mm A", "YYYY-MM-DD HH:mm:ss.ssss") }} </del>
                </p>
                <p v-else>{{ formatDate(item.dateTime, "DD/MM/YYYY hh:mm A", "YYYY-MM-DD HH:mm:ss.ssss") }}</p>
              </div>
              <div v-if="item.type === 'note'" @click="editNote(index)" style="width: width: 75%" class="box-col box-col-right box-col-long col-center-content">
                <p v-if="item.deleted" style="line-height: 1.5;word-break: break-word">
                  <del>{{ item.note }}</del>
                </p>
                <p v-else style="line-height: 1.5;word-break: break-word">{{ item.note }}</p>
              </div>
              <div v-else class="box-col box-col-right box-col-long col-center-content" style="width: 75%">
                <strong>Requested Action: </strong>{{ item.note }}
              </div>
              <div class="pr-8 center-title">
                <img v-if="item.internalOnly === 1" src="@/assets/images/eye-private.svg" />
              </div>
              <div v-if="item.type === 'note' && !item.deleted"  class="is-icon center-title" @click="deleteNote(item, index)">
                <img :class="item.userId !== user.id ? 'disabled-element' : ''" src="@/assets/images/delete_icon.svg" />
              </div>
            </div>
          </div>

          <div class="box-body tab-content tab-content-boxes hidden-element">
            <div>
              <div>
                <table class="table">
                  <thead>
                    <tr>
                      <th style="width:4%">
                        <el-checkbox @change="handleAllSelect"></el-checkbox>
                      </th>
                      <th style="width:14%" class="long-text">
                        File Type
                        <img v-if="listParam.SortFlag === 1" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(2)" />
                        <img v-else-if="listParam.SortFlag === 2" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                        <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(1)"   />
                      </th>
                      <th style="width:22%" class="long-text">
                        File Name
                        <img v-if="listParam.SortFlag === 3" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(4)" />
                        <img v-else-if="listParam.SortFlag === 4" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                        <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(3)"   />
                      </th>
                      <th style="width:15%" class="long-text">
                        Uploaded By
                        <img v-if="listParam.SortFlag === 5" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(6)" />
                        <img v-else-if="listParam.SortFlag === 6" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                        <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(5)"   />
                      </th>
                      <th style="width:15%" class="long-text">
                        Date Uploaded
                        <img v-if="listParam.SortFlag === 7" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(8)" />
                        <img v-else-if="listParam.SortFlag === 8" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                        <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(7)"   />
                      </th>
                      <th style="width:10%" class="icon-col">
                        Status
                        <img v-if="listParam.SortFlag === 9" class="sort-icon" :src="require('@/assets/images/down_arrow_icon.svg')" @click="sortParam(10)" />
                        <img v-else-if="listParam.SortFlag === 10" class="sort-icon" :src="require('@/assets/images/up_arrow_icon.svg')" @click="sortParam(0)" />
                        <img v-else class="sort-icon" :src="require('@/assets/images/default_arrow.svg')" @click="sortParam(9)"   />
                      </th>
                      <th style="width:10%" class="icon-col">Version Count</th>
                      <th style="width:10%" class="icon-col"><div class="icon-group">&nbsp;</div></th>
                    </tr>
                  </thead>
                </table>
              </div>
              <div class="content-scroll-y" style="height:auto;max-height:460px;">
                <table class="table remove-min-height">
                  <tbody :key="fileKey">
                    <tr v-for="(file, index) in booking.bookingFiles" :key="index" class="py-0" :class="file.IsDeleted ? 'deleted-file' : ''">
                      <th style="width:4%" class="long-text">
                        <el-checkbox size="large" v-model="file.checked"></el-checkbox>
                      </th>
                      <th style="width:14%" class="long-text">
                        <select v-model="file.FileTypeID" :disabled="user.type === 4" @change="updateFileType(file.BookingFileID, index)">
                          <option value="" hidden>Select File Type</option>
                          <option v-for="fileType in fileTypes" :key="fileType.id" :value="fileType.id">{{ fileType.name }}</option>
                        </select>
                        <!-- <span v-else style="padding-left: 10px;">{{ file.fileTypeName }}</span> -->
                      </th>
                      <th style="width:22%" class="long-text">
                        <el-tooltip v-if="!file.editing" effect="dark" :content="file.FileName">
                          <span style="cursor: pointer" @click="getFileUrl(file)" >{{ file.FileName }}</span>
                        </el-tooltip>
                        <el-input style="width: 60%" v-else v-model="newFileName" />
<!--                        <div class="ml-3 d-inline">-->
<!--                          <i @click="onChangeEditing(file)" v-if="!file.editing" class="el-icon-edit cursor-pointer"></i>-->
<!--                          <div class="d-inline" v-else>-->
<!--                            <i @click="onSaveEditing(file, index)"  class="el-icon-check cursor-pointer mr-2"></i>-->
<!--                            <i @click="onCancelEditing(file)"  class="el-icon-close cursor-pointer"></i>-->
<!--                          </div>-->
<!--                        </div>-->
                      </th>
                      <th style="width:15%" class="long-text">
                        {{ decodeURIComponent(file.UserName)}}
                      </th>
                      <th style="width:15%" class="long-text">
                        {{ formatDate(file.DateUploaded, 'DD/MM/YYYY HH:mm',  "YYYY-MM-DD HH:mm:ss.ssssss")}}
                      </th>
                      <th style="width:10%" class="long-text">
                        <select v-model="file.FileStatus" @change="finalizeFile(file.BookingFileID, index)">
                          <option v-for="status in fileStatus" :value="status.id">{{ status.name }}</option>
                          <option v-if="file.FileTypeID === 3" value="2">Complete</option>
                          <option v-if="file.FileTypeID === 3" value="3">For Review</option>
                        </select>
                        <!-- <span v-else>Final</span> -->
                      </th>
                      <th style="width:10%" class="long-text">
                        {{ file.VersionCount}}
                      </th>
                      <th style="width:10%; vertical-align:middle" class="icon-col">
                        <div v-if="file.VersionCount === 1" class="icon-group d-flex justify-content-center">
                          <img src="@/assets/images/download_icon.svg" @click="getFileUrl(file)" />
                          <img style="margin-left: 20px" src="@/assets/images/delete_icon.svg" @click="deleteFile(file.BookingFileID, file.FileName, booking.id)" />
                        </div>
                        <div v-else class="icon-group d-flex justify-content-center">
                          <img src="@/assets/images/eye-icon.svg" @click="showVersionModal(file)" />
                        </div>
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="box-col">
              <div class="file-upload-area border-gray-300 mt-5 mb-5" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                <input
                  type="file"
                  multiple
                  name="fields[assetsFieldHandle][]"
                  id="assetsFieldHandle"
                  class="w-px h-px opacity-0 overflow-hidden absolute"
                  @change="onChange"
                  ref="file"
                  accept=".pdf,.jpg,.jpeg,.png,.docx,.xlsx,.txt,.gif,.ds2,.mp3,.mp4,.mov,.AVI,.MKV" />
                <label for="assetsFieldHandle" class="block cursor-pointer d-flex flex-column justify-content-center align-items-center">
                  <div>Drop files in this area or <span style="text-decoration:underline" class="is-icon">click here</span> to upload files.</div>
                  <div>Files over 200MB in file size may cause errors. If you need to upload files of this size, please contact us on 1300 100 733</div>
                </label>
                <FileTypeModal ref="fileTypeModal" />
              </div>
            </div>
          </div>
          <div v-show="isBilling" class="box-body tab-content tab-content-boxes">
            <div class="box-row" v-for="(item, index) in billingNoteAndActions" :key="index">
              <div class="box-col box-col-left" style="width: 12%">{{item.userName}}</div>
              <div class="box-col box-col-left" style="width: 13%">
                <!-- {{item.noteDateTime}} -->
                <p v-if="item.deleted">
                  <del>{{ formatDate(item.dateTime, "DD/MM/YYYY hh:mm A", "YYYY-MM-DD HH:mm:ss.ssss") }} </del>
                </p>
                <p v-else>{{ formatDate(item.dateTime, "DD/MM/YYYY hh:mm A", "YYYY-MM-DD HH:mm:ss.ssss") }}</p>
              </div>
              <div v-if="item.type === 'note'" @click="editNote(index)" style="width: width: 75%" class="box-col box-col-right box-col-long col-center-content">
                <p v-if="item.deleted" style="line-height: 1.5; word-break: break-word">
                  <del>{{ item.note }}</del>
                </p>
                <p v-else style="line-height: 1.5;word-break: break-word">{{ item.note }}</p>
              </div>
              <div v-else class="box-col box-col-right box-col-long col-center-content" style="width: 75%">
                <strong>Requested Action: </strong>{{ item.note }}
              </div>
              <div class="pr-8 center-title">
                <img v-if="item.internalOnly == 1" src="@/assets/images/eye-private.svg" />
              </div>
              <div v-if="item.type == 'note' && !item.deleted"  class="is-icon center-title" @click="deleteNote(item, index)">
                <img :class="item.userId != user.id ? 'disabled-element' : ''" src="@/assets/images/delete_icon.svg" />
              </div>
            </div>
          </div>
          <div class="box-body tab-content tab-content-boxes hidden-element logs">
            <table class="table">
              <thead>
              <tr>
                <th style="width:15%" class="long-text">User</th>
                <th style="width:38%" class="long-text">Recipient</th>
                <th style="width:23%" class="long-text">Subject</th>
                <th style="width:15%" class="long-text">Date/Time sent</th>
                <th style="width:10%" class="long-text">Action</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(item, index) in commonLogs" :key="index" class="py-0">
                <td style="width: 15%" >{{decodeURIComponent(item.Username)}}</td>
                <td style="width: 38%; text-overflow: ellipsis;white-space: nowrap; overflow: hidden;" :title="decodeURIComponent(item.Recipient)" >{{decodeURIComponent(item.Recipient)}}</td>
                <td style="width: 23%; text-overflow: ellipsis;white-space: nowrap; overflow: hidden;" :title="item.CommuType === 1 ? item.Subject : ''" >{{item.CommuType === 1 ? item.Subject : ''}}</td>
                <td style="width: 15%" >{{getDate(item.LogDate)}}</td>
                <td @click="showEmailBody(item)" class="cursor-pointer" style="color: #3FA2F7" >
                  View
                </td>
              </tr>
              </tbody>
            </table>
            <div v-if="commonsCount > commonParam.PageSize" class="pagination">
              Page
              <div class="pagination-group">
                <div class="border-icon pagination-current dropdown">
                  <div class="pagination-current-text">{{ commonParam.PageNum }}</div>
                  <div class="pagination-current-icon" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"></div>
                  <div class="dropdown-menu shadow" aria-labelledby="pagination-current-icon">
                    <div class="dropdown-item" v-for="item in (1, Math.ceil(commonsCount/commonParam.PageSize))" :key="item" :class="commonParam.PageNum === item ? 'selected' : ''" :value="item" @click="sendSuccess(item)">
                      {{ item }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="pagination-total">of &nbsp; {{ Math.ceil(commonsCount/commonParam.PageSize) }}</div>
            </div>
          </div>
          <div class="box-body tab-content tab-content-boxes hidden-element">
            <div class="box-row d-flex flex-column" v-for="(note, index) in notesList" :key="index" :style="{'text-decoration': note.Deleted ? 'line-through' : 'none'}">
              <div class="d-flex align-content-center w-100">
                <div class="box-col box-col-left is-icon" >
                  {{ decodeURIComponent(note.UserName) }}
                </div>
                <div class="box-col box-col-left">{{ formatDate(note.NoteDateTime, "DD/MM/YYYY hh:mm A", "YYYY-MM-DD HH:mm:ss.ssssss") }}</div>
                <div style="max-width: 60%" class="box-col box-col-right long-text" data-toggle="tooltip" data-placement="right" :title="note.Note">
                  {{ decodeURIComponent(note.Note) }}
                </div>
                <div v-if="user.type >= 4 && !note.Deleted" class="is-icon mr-1">
                  <i v-if="!note.children" class="el-icon-chat-dot-square" style="font-size: 20px" @click="openTimeModal(note, index)"/>
                  <i v-else class="el-icon-s-comment" style="font-size: 20px" @click="openTimeModal(note, index)"/>
                </div>
              </div>
              <div class="pl-4 d-flex align-center w-100 font-weight-bold" v-for="item in note.children" :key="item.SpecialistRequestNoteID">
                <div class="box-col box-col-left is-icon" >
                  {{ decodeURIComponent(item.UserName) }}
                </div>
                <div class="box-col box-col-left">{{ formatDate(item.NoteDateTime, "DD/MM/YYYY hh:mm A", "YYYY-MM-DD HH:mm:ss.ssssss") }}</div>
                <div style="max-width: 60%" class="box-col box-col-right long-text" data-toggle="tooltip" data-placement="right" :title="item.Note">
                  {{ decodeURIComponent(item.Note) }}
                </div>
              </div>
            </div>
          </div>
          <div class="alert alert-warning confirmation hidden-element">
            <a class="close" title="close" @click="tapButtonConfirmation(false)">×</a>
            <b>Warning:</b> Are you sure? Your changes will take effect immediately.
            <div class="icon-group">
              <v-btn class="alert-button" @click="tapButtonConfirmation(true)">Yes</v-btn>
              <v-btn class="alert-button" @click="tapButtonConfirmation(false)">No</v-btn>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    <el-dialog
        class="no-padding-dialog"
        :title="currentReply ? 'Reply Note' : 'New Note'"
        :visible.sync="noteDialogVisible"
        @close="closeModal"
        width="50%">
      <div>
        <textarea rows="4" type="text" @keydown.enter.prevent="submitForm" v-model="requestNotes" class="textbox" maxlength="1024" placeholder="Enter a note" />
      </div>
      <span slot="footer" class="dialog-footer">
    <v-btn class="white-button me-2" dark @click="closeModal">Cancel</v-btn>
    <v-btn dark @click="submitForm">Save</v-btn>
  </span>
    </el-dialog>
    <el-dialog
        class="edit-zoho-dialog"
        title="Version List"
        :visible.sync="versionVisible"
        width="50%">
      <div>
        <el-table :data="versionList">
          <el-table-column
              show-overflow-tooltip
              prop="FileTypeName"
              label="File Type"
          />
          <el-table-column
              show-overflow-tooltip
              prop="FileSize"
              label="Size"
          >
            <div slot-scope="item">
              {{ (item.row.FileSize / 1024).toFixed(2)}}KB
            </div>
          </el-table-column>
          <el-table-column
              show-overflow-tooltip
              prop="FileSize"
              label="Upload Time"
          >
            <div slot-scope="item">
              {{formatDate(item.row.LastModified, 'MM/DD/YYYY HH:mm', "YYYY-MM-DD HH:mm:ss.ssss")}}
            </div>
          </el-table-column>
          <el-table-column
              show-overflow-tooltip
              prop="UserName"
              label="Upload By"
          >
          </el-table-column>
          <el-table-column
              show-overflow-tooltip
              prop="FileSize"
              label="Action"
          >
            <div slot-scope="file">
              <img style="cursor: pointer" src="@/assets/images/download_icon.svg" @click="getFileUrl(file.row)" />
              <img style="margin-left: 20px; cursor: pointer" src="@/assets/images/delete_icon.svg" @click="deleteVersionFile(file.row, file)" />
            </div>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer" class="dialog-footer">
        <v-btn dark @click="deleteFile(currentFile.BookingFileID, currentFile.FileName, booking.id)">Remove All</v-btn>
      </span>
    </el-dialog>
    <CancelBookingModal ref="cancelBookingModal"/>
    <AdminManageContactDetailsModal ref="adminManageContactModal" />
    <AdminSendEmailModal @sendSuccess="sendSuccess" :client="client" :specialist="specialist" :clientContact="clientContacts" :bookingProps="booking" :locationName="getLocationName(booking.locationId)" :sendToProps="sendToProps" :bookingFiles="booking.bookingFiles" :emailProps="emails" :nameProps="names" :phoneProps="phones" :emailSmsProps="emailOrSms" ref="adminSendEmailModal" />
  </div>
</template>

<script>
  import utilities from '@/assets/js/utilities'
  import AdminNavigation from '@/components/AdminNavigation'
  import AdminBookingsMenu from '@/components/AdminBookingsMenu'
  import Header from '@/components/Header'
  import AddNoteModal from '@/components/AddNoteModal'
  import Timeline from '@/components/Timeline'
  import CancelBookingModal from '@/components/CancelBookingModal'
  import AdminManageContactDetailsModal from '@/components/AdminManageContactDetailsModal'
  import AdminSendEmailModal from '@/components/AdminSendEmailModal'
  import AdminAddServiceTimeModal from '@/components/AdminAddServiceTimeModal'
  import RequestActionModal from '@/components/RequestActionModal'
  import AddBodyDiagramModal from '@/components/AddBodyDiagramModal'
  import FileTypeModal from '@/components/FileTypeModal'
  import JSZip from "jszip";
  import FileSaver from "file-saver";

  export default {
    name: 'AdminManageBooking',
    components: {
      AdminNavigation,
      AdminBookingsMenu,
      Header,
      AdminManageContactDetailsModal,
      AddNoteModal,
      Timeline,
      AdminSendEmailModal,
      AdminAddServiceTimeModal,
      RequestActionModal,
      AddBodyDiagramModal,
      FileTypeModal,
      CancelBookingModal
    },
    data() {
      return {
        listParam: {
          SortFlag: 0,
        },
        requestNotes: '',
        noteDialogVisible: false,
        curTab: 0,
        newFileName: '',
        notesList: [],
        currentNotes: null,
        loadingUser:false,
        userParam: {
          PageSize: 20,
          PageNum: 1,
          SortField: 'UserStatus',
          SortBy: 'DESC',
          UserName: null,
          RoleID: '3,6',
        },
        commonParam: {
          PageSize: 10,
          PageNum: 1,
          BookingID: this.$route.query.id
        },
        commonsCount: 0,
        currentFile: null,
        versionVisible: false,
        showLinkLocation: true,
        curSpecialty: '',
        billToModel: null,
        sendToModel: null,
        isBilling: false,
        dialogVisible: false,
        bodyAreasTag: '',
        conditionTag: '',
        originalStatus: '',
        hasError: false,
        loaded: false,
        focused: '',
        focusDropdown: false,
        specialistFocus: false,
        bookingFocus: false,
        locationFocus: false,
        bookingTypeFocus: false,
        attendanceStatusFocus: false,
        paperworkStatusFocus: false,
        sendReportToFocus: false,
        onBehalfOfFocus: false,
        billToFocus: false,
        preApprovedHoursFocus: false,
        hoursForFocus: false,
        interpreterRequired: false,
        surrogateRequired: false,
        showSurrogate: true,
        showError: false,
        showOther: false,
        showContent: true,
        isTimeChanged: false,
        showTime: true,
        enableButton: true,
        isBookingDateChanged: false,
        isValidPaperworkDue: true,
        hideButton: false,
        isValidAccidentDate: true,
        showSupplementaryService: false,
        showClientNotification: true,
        commonLogs: [],

        showBillToEmail: false,
        showSendReportToOther: false,
        listContact: [],
        originalClientContacts: [],
        togglePeriodOfTime: false,

        overTime: false,
        bodyAreaTag: "",
        otherBodyAreaTag: "",
        personConditionTag: "",
        bodyAreas: [],
        bodyAreaTags: [],
        otherBodyAreaTags: [],
        originalOtherBodyAreaTags: [],
        deletedBodyTags: [],
        personConditions: [],
        personConditionTags: [],
        originalPersonConditionTags: [],
        deletedConditionTags: [],
        addedBodyArea: [],
        addedConditions: [],
        isLolChanged: false,
        isBrirgChanged: false,
        isAdditionalChanged: false,
        isPaperworkChanged: false,
        isChangedAttendance: false,
        isChangedConsent: false,
        isShowForBooking: true,
        isShowBookingTime: false,
        hidePurchaseOrderButton: false,
        clients: [],
        test: -1,

        preApprovedHours: [
          {
            hours: '',
            hoursFor: '',
          },
        ],
        requestedExtraHours: [
          {
            hours: 1,
            hoursFor: 1,
          },
        ],

        specialists: [],
        specialist: [],
        bodyAreasTags: [],
        conditionTags: [],
        filelist: [],
        locations: [],
        bodyAreasAutocomplete: [],
        conditionsAutocomplete: [],
        clientContacts: [],
        paperworkStatuses: [],
        bookingNoteAndActions: [],
        billingNoteAndActions: [],
        standardFiles: [],
        availableTimes: [],
        states: [],

        user: _.cloneDeep(this.$store.getters['user/getUser']),
        bookingStatuses: utilities.getBookingStatuses().filter(item => item.id !== 6), // booking status without cancel
        bookingTypes: [],
        claimTypes: [],
        booking: utilities.initBooking(),
        originalBooking: utilities.initBooking(), // original booking
        fileStatus: utilities.getFileStatus(),
        statusList: utilities.getStatus(),
        bookingLogs: [],
        timelineData: [],
        minimumAppointment: [],
        isUpdated: false,
        isDisabled: false,
        disableBookingType: true,
        currentReply: {},
        reports: [
          {
            id: 1,
            name: 'John',
          },
          {
            id: 2,
            name: 'Kate',
          },
        ],
        deleteId: '',
        deleteIndex: '',
        roleProps: 'admin',

        sendToProps: '',

        successMessage: '',
        errorMessage: '',
        emails: {},
        bookingAction: [],
        fileTypeId: 0,
        fileTypes: [],
        fileKey: 1,
        users: [],
        bookingDate: moment().format('DD/MM/YYYY'),
        bookingTime: moment().format('hh:mm A'),
        originalBookingDate: '',
        originalBookingTime: '',
        bookingOldDateTime: '',
        statusKey: 0,
        singleCase: {},
        client: {},
        names: [],
        additionalHoursStatus: utilities.getAdditionalHoursStatuses(),
        versionList: [],
        phones: {},
        emailOrSms: 0,
        noteKey: 0,
        timeKey: 0,
        additionalTime: 0,
        minLength: 60,
        availabilityEndTime: '',
        newSlot: [],
        specialistTimes: [],
      }
    },
    watch: {
      booking: {
        handler(value) {
          if (this.loaded) {
            this.$store.dispatch("setNewObject", value)
            let originalObject = this.$store.getters["getOriginalObject"]
            originalObject.billingNotes = value.billingNotes
            originalObject.bookingNotes = value.bookingNotes
            originalObject.bookingFiles = value.bookingFiles
            this.$store.dispatch("setOriginalObject", originalObject)
          }
        },
        deep: true,
      },
    },
    computed: {
      validateBillEmail() {
        if (this.showBillToEmail) {
          if(this.booking.billTo != "" && !utilities.validateEmail(this.booking.billTo)) {
            return false
          }
        }
        return true
      },

      validateSendEmail() {
        if (this.showSendReportToOther) {
          if(this.booking.reportTo != "" && !utilities.validateEmail(this.booking.reportTo)) {
            return false
          }
        }
        return true
      },

      validateState() {
        if ((this.booking.bookingTypeId == 4 || this.booking.bookingTypeId == 7 || this.booking.bookingTypeId == 15) && this.booking.claimantStateDuringBooking == '') {
          return false
        }
        return true
      },

      validateBookingTime() {
        if ((this.booking.bookingTypeName != "Supplementary by Video" && this.booking.bookingTypeName != "Medical Negligence Request"
          && this.booking.bookingTypeName != "Supplementary" && this.booking.bookingTypeName != "File Review")
          && this.bookingTime == '') {
          return false
        }
        return true
      },

      getCaseOwnerName() {
        if (this.booking == undefined || this.booking.bookingId == '') {
          return
        }
        if (this.booking.clientContacts.length > 0) {
          return this.booking.clientContacts[0].clientContactFullName
        } else {
          if (this.client.contacts == undefined || this.client.contacts.length == 0) return ''
          let caseOwner;
          caseOwner = this.client.contacts[0]
          if (caseOwner != undefined) {
            return caseOwner.fullName
          } else {
            return ' '
          }
        }
      },

      getCaseOwnerPhone1() {
        if (this.booking.clientContacts.length > 0) {
          return this.booking.clientContacts[0].phone
        } else {
          if (this.client.contacts == undefined || this.client.contacts.length == 0) return ''
          let caseOwner;
          caseOwner = this.client.contacts[0]
          if (caseOwner != undefined) {
           return caseOwner.phoneNumber1
          } else {
            return ' '
          }
        }
      },
      getCaseOwnerMobile() {
        if (this.booking.clientContacts.length > 0) {
          return this.booking.clientContacts[0].mobile
        } else {
          if (this.client.contacts == undefined || this.client.contacts.length == 0) return ''
          const caseOwner = this.client.contacts[0]
          if (caseOwner != undefined) {
           return caseOwner.mobile
          } else {
            return ' '
          }
        }
      },
      getCaseOwnerPhone2() {
        if (this.booking.clientContacts.length > 0) {
          return this.booking.clientContacts[0].phone2
        } else {
          if (this.client.contacts == undefined || this.client.contacts.length == 0) return ''
          caseOwner = this.client.contacts[0]
          if (caseOwner != undefined) {
           return caseOwner.phoneNumber2
          } else {
            return ' '
          }
        }
      },
      getCaseOwnerEmail() {
        if (this.booking.clientContacts.length > 0) {
          return this.booking.clientContacts[0].NotificationEmailAddress || this.booking.clientContacts[0].email
        } else {
          if (this.client.contacts == undefined || this.client.contacts.length == 0) return ''
          const caseOwner = this.client.contacts[0]
          if (caseOwner != undefined) {
            return caseOwner.NotificationEmailAddress || caseOwner.emailAddress
          } else {
            return ' '
          }
        }
      },

      getAttendanceTitle() {
        var title = "Claimant attendance " + (this.isDate(this.booking.claimantConfirmed) || this.booking.claimantConfirmed ? "" : "not ") + "confirmed"
        return title
      },

      getOTFCETitle() {
        var title = "OTFCE " + (this.booking.otfceRequired ? "" : "not ") + "required"
        return title
      },

      getCanRingClaimantTitle() {
        var title = this.booking.redHealthMayContactClaimant ? "Can ring claimant" : "Can not ring claimant"
        return title
      },

      getConsentReceivedTitle() {
        var title = "Claimant consent " + (this.isDate(this.booking.claimantConsentReceived) || this.booking.claimantConsentReceived ? "" : "not ") + "received"
        return title
      },

      getCallAfterAppointmentTitle() {
        var title = (this.booking.callClientAfterAppointment ? "Phone " : "No ") + "call required"
        return title
      },

      getVideoLinkTestArrangedTitle() {
        var title = "Video link test " + (this.booking.videoLinkTestStatus == 1 || this.booking.videoLinkTestStatus == 2  ? "" : "not ") + "arranged"
        return title
      },

      getHasVideoTitle() {
        var title = "Video link test " + (this.booking.videoLinkTestStatus == 2 ? "" : "not ") + "completed"
        return title
      },

      getFeeDeferralRequiredTitle() {
        var title = this.booking.feeDeferralRequired ? "Payment deferral required" : "No payment deferral"
        return title
      },

      getPrePaymentRequiredTitle() {
        var title = "Prepayment " + (this.booking.prepaymentRequired ? "" : "not ") + "required"
        return title
      },

      getSpecialistConfirmedHoursTitle() {
        var title = "Specialist " + (this.booking.specialistConfirmedHours ? "confirmed hours" : "hours not confirmed")
        return title
      },

      hasChange() {
        var result = false
        // Format Accident Date after selected from the calendar
        if(typeof this.booking.accidentDate != 'string' && moment(this.booking.accidentDate, "YYYY-MM-DD").isValid()) {
          this.booking.accidentDate = moment(this.booking.accidentDate, "YYYY-MM-DD").format("YYYY-MM-DD")
        }
        // Format Paperwork Due after selected from the calendar
        if(typeof this.booking.paperworkDue != 'string' && moment(this.booking.paperworkDue, "YYYY-MM-DD").isValid()) {
          this.booking.paperworkDue = moment(this.booking.paperworkDue, "YYYY-MM-DD").format("YYYY-MM-DD")
        }
        this.originalBooking.bookingNotes = _.cloneDeep(this.booking.bookingNotes)
        this.originalBooking.billingNotes = _.cloneDeep(this.booking.billingNotes)
        if(_.isEqual(this.originalBooking, this.booking)
          && _.isEqual(this.originalOtherBodyAreaTags, this.otherBodyAreaTags)
          && _.isEqual(this.originalPersonConditionTags, this.personConditionTags)
          && moment(this.originalBookingDate, "YYYY-MM-DD").isSame(moment(this.bookingDate, "YYYY-MM-DD"))
          && moment(this.originalBookingDate + " " + this.originalBookingTime, "YYYY-MM-DD HH:mm:ss.ssssss").isSame(moment(this.bookingDate + " " + this.bookingTime, "YYYY-MM-DD HH:mm:ss.ssssss"))) {
          result = false
        }
        else {
          result = true
        }
        return result
      },

      filteredBodyAreaItems() {
        return this.bodyAreas.filter(i => {
          return i.text.toLowerCase().indexOf(this.bodyAreaTag.toLowerCase()) !== -1;
        });
      },

      filteredPersonConditionsItems() {
        return this.personConditions.filter(i => {
          return i.text.toLowerCase().indexOf(this.personConditionTag.toLowerCase()) !== -1;
        });
      },

      formattedAddress() {
        var address = ''
        if (this.booking.claimantAddressLine1 != '') address += this.booking.claimantAddressLine1 + ', '
        if (this.booking.claimantAddressLine2 != '') address += this.booking.claimantAddressLine2 + ', '
        if (this.booking.claimantCity != '') address += this.booking.claimantCity + ', '
        if (this.booking.claimantState != '') address += this.booking.claimantState + ' '
        if (this.booking.claimantPostcode != '') address += this.booking.claimantPostcode
        return address
      },
      preapprovedHours() {
        return this.booking.bookingHours.filter((x) => x.status == 3)
      },
      additionalHours() {
        return this.booking.bookingHours.filter((x) => x.status != 3)
      },
      interpreterDetails: {
        get: function() {
          return this.booking.interpreterDetails
        },
        set: function(newValue) {
          if (newValue.trim() != '') this.booking.interpreterRequired = true
          else this.booking.interpreterRequired = false
          this.booking.interpreterDetails = newValue
        },
      },
      surrogateDetails: {
        get: function() {
          return this.booking.surrogateDetails
        },
        set: function(newValue) {
          if (newValue.trim() != '') this.booking.surrogateRequired = true
          else this.booking.surrogateRequired = false
          this.booking.surrogateDetails = newValue
        },
      },
      bookingDateTimeComputed: {
        get: function() {
          return moment(this.booking.bookingDateTime, 'YYYY-MM-DD HH:mm:ss.SSSSSS').format('DD/MM/YYYY hh:mm A')
        },
        set: function(newValue) {
          this.booking.bookingDateTime = moment(newValue, 'DD/MM/YYYY hh:mm A').format('YYYY-MM-DD HH:mm:ss')
        },
      },
      isCancelled() {
        return this.$route.query.cancelled != undefined && this.$route.query.cancelled
      },
    },
    methods: {
      handleAllSelect(e) {
        this.booking.bookingFiles.forEach(item => {
          item.checked = e
        })
        this.$forceUpdate()
      },
      openTimeModal(note, index) {
        if(note) {
          this.currentReply = {note: note, index: index}
        } else {
          this.currentReply = null
        }
        this.noteDialogVisible = true
      },
      closeModal() {
        this.requestNotes = ''
        this.noteDialogVisible = false
      },
      async submitForm() {
        const param = {
          BookingID: this.booking.id,
          NoteDateTime: window.moment().format('YYYY-MM-DD HH:mm:ss'),
          Note: this.requestNotes,
          Category: this.currentReply ? 'Reply' : 'Request',
          RequestID: this.currentReply?.note?.SpecialistRequestNoteID
        }
        this.$store.dispatch('loading/setLoadComponent', true)
        const result = await utilities.postSpecialistNote(param)
        if(result) {
          this.closeModal()
          await this.getSpecialistNotes()
        }
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      onChangeEditing(file) {
        this.booking.bookingFiles.forEach(item => {
          item.editing = false
        })
        this.newFileName = file.FileName
        file.editing = true
        this.$forceUpdate()
      },
      async onSaveEditing(file, index) {
        file.editing = false
        await this.updateFileType(file.BookingFileID, index, true)
      },
      onCancelEditing(file) {
        file.editing = false
        this.newFileName = ''
      },
      async showVersionModal(file) {
        this.$store.dispatch('loading/setLoadComponent', true)
        this.versionList = await utilities.getBookingFileVersion({
          BookingID: this.booking.id,
          BookingFileID: file.BookingFileID,
          PageNum: 1,
          PageSize: 999
        })
        this.currentFile = file
        this.versionVisible = true
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      showEmailBody(item) {
        let obj = item.LogDetails
        if(item.CommuType === 2) {
          obj = obj.replace(/\n/g,'<br>')
          obj = obj.replace(/\\n/g,'<br>')
        }
        let preFill = ''
        if(JSON.parse(item.Attachments) && JSON.parse(item.Attachments).length > 0 && JSON.parse(item.Attachments)[0].FileName) {
          preFill = '<b>Attachments: </b>'
          preFill += JSON.parse(item.Attachments).map(item => item.FileName).join(', ')
        }
        this.$alert(preFill + obj, 'Email /SMS Content', {
          dangerouslyUseHTMLString: true,
          confirmButtonText: 'OK',
          customClass: 'w-75 p-4 alert-msg-custom'
        });
      },
      getDate(date) {
        return window.moment(date).format('DD/MM/YYYY HH:mm')
      },
      sortParam(param = 7) {
        this.listParam.SortFlag = param
        switch (param) {
          case 1:
            this.booking.bookingFiles.sort((a, b) => {
              return (b.FileTypeName >= a.FileTypeName) ? 1 : -1
            })
            break;
          case 2:
            this.booking.bookingFiles.sort((a, b) => {
              return (a.FileTypeName >= b.FileTypeName) ? 1 : -1
            })
            break;
          case 3:
            this.booking.bookingFiles.sort((a, b) => {
              return (b.FileName >= a.FileName) ? 1 : -1
            })
            break;
          case 4:
            this.booking.bookingFiles.sort((a, b) => {
              return (a.FileName >= b.FileName) ? 1 : -1
            })
            break;
          case 5:
            this.booking.bookingFiles.sort((a, b) => {
              return (b.UserName >= a.UserName) ? 1 : -1
            })
            break;
          case 6:
            this.booking.bookingFiles.sort((a, b) => {
              return (a.UserName >= b.UserName) ? 1: -1
            })
            break;
          case 7:
            this.booking.bookingFiles.sort((a, b) => {
              return Date.parse(b.DateUploaded) - Date.parse(a.DateUploaded)
            })
            break;
          case 8:
            this.booking.bookingFiles.sort((a, b) => {
              return Date.parse(a.DateUploaded) - Date.parse(b.DateUploaded)
            })
            break;
          case 9:
            this.booking.bookingFiles.sort((a, b) => {
              return b.FileStatus - a.FileStatus
            })
            break;
          case 10:
            this.booking.bookingFiles.sort((a, b) => {
              return a.FileStatus - b.FileStatus
            })
            break;
        }
      },
      async bookingCancel() {
        this.booking.bookingStatus = 6
        this.successMessage = 'The booking has now been cancelled.'
        utilities.showAlert(".alert-success")
        await this.loadBooking()
      },

      tapCancel() {
        this.$refs.cancelBookingModal.handleModal()
      },
      async getSpecialistNotes() {
        let bookingId = this.$route.query != undefined && this.$route.query.id != undefined ? this.$route.query.id : ''
        const result = await utilities.getSpecialistNote({PageNum:1, PageSize: 10, BookingID: bookingId})
        let arr = result.filter(item => !item.RequestID)
        result.forEach(item => {
          if(item.RequestID) {
            let obj = arr.find(a => a.SpecialistRequestNoteID === item.RequestID)
            if(obj) {
              if(!obj.children) {
                obj.children = [item]
              } else {
                obj.children.push(item)
              }
            }
          }
        })
        this.notesList = arr
      },
      getOriginalBooking() {
        if (!this.isUpdated) {
          this.originalBooking = _.cloneDeep(this.booking)
          this.originalOtherBodyAreaTags = _.cloneDeep(this.otherBodyAreaTags)
          this.originalPersonConditionTags = _.cloneDeep(this.personConditionTags)
          this.originalBookingDate = this.bookingDate
          this.originalBookingTime = this.bookingTime
        }
      },

      checkBookingObjectisUpdated() {
        if (_.isEqual(this.originalBooking, this.booking)) {
          this.isUpdated = false
        } else {
          this.isUpdated = true
        }
      },

      setDefaultStatus() {
        this.isLolChanged = false
        this.isBrirgChanged = false
        this.isAdditionalChanged = false
        this.isPaperworkChanged = false
        this.isChangedAttendance = false
        this.isChangedConsent = false
      },

      tapSupplementaryService() {
        this.$router.push({ name: 'admin-supplementary-service-request', query: { id: this.booking.id } }).catch((err) => {})
      },
      toEditPage() {
        const routeData = this.$router.resolve({ path: '/admin-manage-availability', query: { id: this.booking.specialistId } })
        window.open(routeData.href, '_blank')
      },

      openDigram() {
        if(this.booking.bookingStatus === 6) return
        this.openBodyDiagram()
      },

      openBodyDiagram() {
        if(this.booking.bookingStatus === 6) return
        this.$refs.addBodyDiagramModal.handleModal()
        this.$refs.addBodyDiagramModal.filteredBodyAreas = this.bodyAreas
        this.$refs.addBodyDiagramModal.bodyAreas = _.cloneDeep(this.bodyAreaTags)
        this.$refs.addBodyDiagramModal.deleteItems = []
        this.$refs.addBodyDiagramModal.handleClick()
      },

      async setBodyArea(newBodyTags) {
        this.$store.dispatch("loading/setLoadComponent", true)
        await this.callUpdateBodyAreaAPI(newBodyTags)
        this.$store.dispatch("loading/setLoadComponent", false)
      },

      deleteBodyAreaItems(items) {
        items.map((x) => {
          this.deletedBodyTags.push(x.tag)
        })
      },

      async callUpdateBodyAreaAPI(newBodyTags) {
        var bodyAreaObjects = []
        var bodyArea = {}

        // Pass deleted body Area object
        if (this.deletedBodyTags.length > 0) {
          this.deletedBodyTags.map((x) => {
            var temp = this.booking.bodyAreas.find((y) => y.bodyArea == x.text)
            if (temp) {
              bodyArea = {
                BodyAreaID: temp.bodyAreaId,
                BookingBodyAreaID: temp.bookingBodyAreaId
              }
              bodyAreaObjects.push(bodyArea)
            }
          })
        }

        // Pass new body Area object
        if (newBodyTags.length > 0) {
          newBodyTags.map((x) => {
            var temp = this.booking.bodyAreas.find((y) => y.bodyArea == x.text)
            if(temp == undefined) {
              bodyArea = {
                BodyAreaID: x.value
              }
              bodyAreaObjects.push(bodyArea)
            }
          })
        }

        if (bodyAreaObjects.length > 0) {
          var params = {
            BookingId: this.booking.id,
            BodyAreaObject: bodyAreaObjects,
            UserID: this.user.id,
            Username: this.user.name,
          }
          var result = await utilities.putBookingBodyAreaPersonCondition(params)
          if(result) {
            this.bodyAreaTags = _.cloneDeep(newBodyTags)
            // Get body Areas after updating
            var booking = await utilities.getBookingById(this.booking.id)
            if(booking) this.booking.bodyAreas = _.cloneDeep(booking.bodyAreas)
            utilities.showSuccessMessage("Booking details updated.")
          }
          this.deletedBodyTags = []
          this.checkBookingObjectisUpdated()
          this.getOriginalBooking()
        }
      },

      async updatePersonConditions() {
        var conditionObject = []
        var condition = {}

        if (this.personConditionTags.length > 0) {
          this.personConditionTags.map((x) => {
            var temp = this.addedConditions.filter((y) => y.name == x.text)
            if (temp == undefined || temp.length == 0) {
                condition = {
                  PersonConditionID: x.value
                }
                conditionObject.push(condition)
            }
          })
        }

        //Pass deleted PersonCondition object
        if (this.deletedConditionTags.length > 0) {
          this.deletedConditionTags.forEach((item, i) => {
            this.addedConditions.forEach((added, i) => {
              if (item.text == added.name) {
                var condition = this.personConditions.filter((y) => y.text == item.text)[0]
                condition = {
                  PersonConditionID: condition.value,
                  BookingPersonConditionID: added.bookingPersonConditionId
                }
                conditionObject.push(condition)
              }
            });
          });
        }

        if (conditionObject.length > 0) {
          var params = {
            BookingId: this.booking.id,
            PersonConditionObject: conditionObject,
            UserID: this.user.id,
            Username: this.user.name,
          }
          var result = await utilities.putBookingBodyAreaPersonCondition(params)
          if(result) {
            var booking = await utilities.getBookingById(this.booking.id)
            if(booking) this.booking.personConditions = _.cloneDeep(booking.personConditions)
            if (this.booking.personConditions.length > 0) {
              this.personConditionTags = []
              this.deletedConditionTags = []
              this.addedConditions = _.cloneDeep(this.booking.personConditions)
              this.personConditionTags = utilities.getAutoCompleteTags(this.booking.personConditions)
            }
          }
        }
      },

      deleteBodyArea(e) {
        if(this.booking.bookingStatus === 6) return
        this.openDigram()
        // // Filter from list
        // if (e.tag.caseId !== undefined) {
        //   // Delete
        //   this.singleCase.bodyAreas = this.singleCase.bodyAreas.filter((x) => x.caseBodyAreaId !== e.tag.id)
        //   // Call delete API
        //   this.$store.dispatch('loading/setLoadComponent', true)
        //   var params = {
        //     CaseID: this.singleCase.id,
        //     BodyAreaObject: [{ CaseBodyAreaID: e.tag.id }],
        //     ClientID: this.singleCase.clientId,
        //     ClaimTypeID: this.singleCase.claimTypeId,
        //     ClaimTypeName: this.claimTypes.find((x) => x.id == this.singleCase.claimTypeId).name,
        //     CaseOwner: this.singleCase.caseOwner,
        //     CaseReference: this.singleCase.caseReference,
        //     UserID: this.user.id,
        //     Username: this.user.name,
        //   }
        //   await utilities.deleteFromCase(params)
        //   this.$store.dispatch('loading/setLoadComponent', false)
        // }
        // this.bodyAreas.splice(e.index, 1)
        // e.deleteTag()
      },

      async deletePersonCondition(obj) {
        if(this.booking.bookingStatus === 6) return
        this.deletedConditionTags.push(obj.tag)
        this.personConditionTags.splice(obj.index, 1);
        this.booking.personConditions = this.personConditionTags
      //   // Filter from list
      //   if (e.tag.caseId !== undefined) {
      //     // Delete
      //     this.booking.personCondition = this.booking.personConditions.filter((x) => x.id !== e.tag.id)
      //     // Call delete API
      //     this.$store.dispatch('loading/setLoadComponent', true)
      //     var params = {
      //       CaseID: this.singleCase.id,
      //       ConditionObject: [{ CasePersonConditionID: e.tag.id }],
      //       ClientID: this.singleCase.clientId,
      //       ClaimTypeID: this.singleCase.claimTypeId,
      //       ClaimTypeName: this.claimTypes.find((x) => x.id == this.singleCase.claimTypeId).name,
      //       CaseOwner: this.singleCase.caseOwner,
      //       CaseReference: this.singleCase.caseReference,
      //       UserID: this.user.id,
      //       Username: this.user.name,
      //     }
      //     await utilities.deleteFromCase(params)
      //     this.$store.dispatch('loading/setLoadComponent', false)
      //   }
      //   e.deleteTag()
      },

      changePaperworkDue() {
        setTimeout(() => {
          this.isValidAccidentDate = this.validateAccidentDate()
        }, 200)
      },

      clearPaperworkDue() {
        if(this.booking.bookingStatus === 6) return
        this.booking.paperworkDue = ''
      },

      changeOverTime() {
        this.booking.overPeriodofTime = !this.booking.overPeriodofTime
        if (this.booking.overPeriodofTime) {
          this.booking.accidentDate = ''
        } else {
          this.booking.accidentDate = this.originalBooking.accidentDate
        }
        this.isValidAccidentDate = this.validateAccidentDate()
      },
      selectAccientDate() {
        this.booking.overPeriodofTime = false
      },

      updateConditions(newTags) {
        // if(this.$refs.conditions.newTag.length == 0) newTags.pop() //This one commented commitID-17666983
        this.personConditionTags = newTags
        this.booking.personConditions = this.personConditionTags
      },

      updateOtherBodyArea(newTags) {
        this.otherBodyAreaTags = newTags
        this.booking.bodyAreaOther = this.otherBodyAreaTags
      },

      validateAccidentDate() {
        if ($(".accient-date input").val().length > 0) {
          if((this.singleCase.accidentDate == '' || !moment($(".accient-date input").val(), "DD/MM/YYYY").isValid()) && !this.overTime) {
            return false
          }
        }
        return true
      },

      filterReportTo(text) {
        this.listClients = this.originalClientContacts.filter((item) => item.name.toLowerCase().indexOf(text.toLowerCase()) > -1);
      },

      selectedBillTo(item) {
        if(!item) {
          this.booking.billTo = ""
          this.showBillToEmail = false
          return
        }
        if (item.id === -1) {
          this.booking.billTo = ""
          this.showBillToEmail = true
        } else {
          this.booking.billTo = item.value
          this.showBillToEmail = false
        }
      },

      selectedSendTo(item) {
        if(!item) {
          this.booking.reportTo = ""
          this.showSendReportToOther = false
          return
        }
        if (item.id === -1) {
          this.booking.reportTo = ""
          this.showSendReportToOther = true
        } else {
          this.showSendReportToOther = false
          this.booking.reportTo = item.value
        }
      },

      updateBookingLog(e) {
        this.getTimelineData(e.target.value)
      },
      changeAccientDate() {
        setTimeout(() => {
          this.isValidPaperworkDue = this.validPaperworkDue()
        }, 200)
      },
      validPaperworkDue() {
        if ($(".paperwork-due input").val() != undefined && $(".paperwork-due input").val().length > 0) {
          if((this.booking.paperworkDue != '' && !moment($(".paperwork-due input").val(), "DD/MM/YYYY").isValid()) && !this.overTime) {
            return false
          }
        }
        return true
      },

      checkBookingType() {
        if(this.booking.bookingTypeId === 4 || this.booking.bookingTypeId === 15) {
          this.showLinkLocation = true
        } else {
          this.showLinkLocation = false
          this.booking.ClaimantLocation = null
        }
      },

      changeBookingType() {
        this.checkBookingType()
        this.booking.bookingTypeName = this.bookingTypes.find((x) => x.id == this.booking.bookingTypeId).name
        if (this.booking.bookingTypeName == "Supplementary by Video" || this.booking.bookingTypeName == "Medical Negligence Request" || this.booking.bookingTypeName == "Supplementary" || this.booking.bookingTypeName == "File Review") {
          this.isBookingDateChanged = false
          this.isTimeChanged = false
          this.isShowForBooking = false
        } else {
          setTimeout(async () => {
            var date = moment(this.bookingDate, "YYYY-MM-DD").format('YYYY-MM-DD')
            if(this.originalBookingTime != "") {
              this.bookingTime = this.originalBookingTime
              this.booking.bookingDateTime = date + " " +  moment(this.bookingTime, 'hh:mm:ss A').format("HH:mm:ss.ssssss")
            } else {
              this.bookingTime = ""
            }

            this.availableTimes = []
            this.enableButton = true
          }, 200)
          this.isShowForBooking = true
        }
      },

      calculateBookingEndTime() {
        if (this.booking.bookingTypeId == 6 || this.booking.bookingTypeId == 8) {
          return ''
        }

        var startTime = window.moment(this.booking.bookingDateTime, 'YYYY-MM-DD HH:mm:ss').format('hh:mm A')
        var endTime = this.newSlot.find((x) => x.startTime == startTime).endTime

        let acctualEndTime = window.moment(endTime, 'hh:mm A').format('HH:mm:ss')
        this.booking.bookingEndTime = acctualEndTime
        return this.booking.bookingEndTime
      },

      async updateStatus(e, index) {
        this.$store.dispatch('loading/setLoadComponent', true)
        var item = this.additionalHours[index]
        var params = {
          BookingID: this.booking.id,
          BookingHoursStatus: Number(e.target.value),
          BookingHoursID: item.id,
          UserID: this.user.id,
          Username: this.user.name,
        }
        var callback = (response) => {
        }
        await utilities.putAdditionHoursStatus(params, callback)
        var params2 = {
          Note: `${this.additionalHours[index].service} ${this.additionalHours[index].numberOfHours} hour: ${item.reason}.  ${Number(e.target.value) === 1 ? 'APPROVED' : 'REJECT'}`,
          NoteDateTime: window.moment().format('YYYY-MM-DD HH:mm:ss'),
          UserID: this.user.id,
          Username: this.user.name,
          BookingID: this.booking.id,
          BookingDateTime: this.booking.bookingDateTime,
          SpecialistName: this.booking.specialistFullName,
          ClientName: this.booking.clientName + ' - ' + this.booking.branchName,
          BookingTypeName: this.booking.bookingTypeName,
          InternalOnly: 1,
          IsMainEdit: 0,
          IsAdmin: true
        }
        let callback1 = (response) => {
          let newNote = {
            note: `${this.additionalHours[index].service} ${this.additionalHours[index].numberOfHours} hour: ${item.reason}.  ${Number(e.target.value) === 1 ? 'APPROVED' : 'REJECT'}`,
            noteDateTime: window.moment().format('YYYY-MM-DD HH:mm:ss'),
            id: response,
            userName: this.user.name,
            userId: this.user.id,
            deleted: false,
          }
          this.booking.billingNotes.unshift(newNote)
          this.setBillingNoteAndAction()
        }
        await utilities.postBillingNote(params2, callback1)
        this.$store.dispatch('loading/setLoadComponent', false)
      },

      editNote(index) {
        if(this.booking.bookingStatus === 6) return
        this.checkBookingObjectisUpdated()
        var item = this.isBilling ? this.billingNoteAndActions[index] : this.bookingNoteAndActions[index]
        if (!item.deleted) {
          var note = this.isBilling ? this.booking.billingNotes.find(x => x.id === item.id) :  this.booking.bookingNotes.find(x => x.id === item.id)
          if (note.userId === this.user.id) {
            this.$refs.addNoteModal.isEditClient = true
            this.$refs.addNoteModal.internalOnly = note.internalOnly
            this.$refs.addNoteModal.editCurrentNote(note, false)
            this.$refs.addNoteModal.handleModal()
          }
        }

      },

      calculateBookingDuration(date, startTime, endTime) {
        if (date && startTime && endTime) {
          var duration = moment.duration(moment(date + " " + endTime, "YYYY-MM-DD hh:mm A").diff(moment(date + " " + startTime, "YYYY-MM-DD hh:mm A")))
          var length = duration.asMinutes()
          if (length) {
            return length
          }
        }
       return ""
      },

      tapCancelConfirmation(states) {
        utilities.hideAlert('.alert-warning-confirm')
      },

      // List push
      pushToPreApprovedHours() {
        this.preApprovedHours.push({ hours: '', hoursFor: '' })
      },
      toggleBoxDisplay() {
        if ($('.case-container>.box-body').is(':visible')) {
          $('.case-container>.box-body').fadeOut()
        } else {
          $('.case-container>.box-body').fadeIn()
        }
      },
      openTab(index) {
        if(index === 2) {
          this.isBilling = true
        } else {
          this.isBilling = false
        }
        this.curTab = index
        utilities.openTab(index)
      },
      downloadBtn(fileList) {
        var blogTitle = moment().format('DD/MM/YYYY'); // 下载后压缩包的名称
        var zip = new JSZip();
        var promises = [];
        let cache = {};
        for (let item of fileList) {
          if(item.filePath) {
            const promise = this.getImgArrayBuffer(item.filePath).then((data) => {
              zip.file(item.fileName, data, { binary: true });
              cache[item.fileName] = data;
            });
            promises.push(promise);
          }
        }
        Promise.all(promises).then(() => {
          zip.generateAsync({ type: "blob" }).then((content) => {
            FileSaver.saveAs(content, blogTitle); // 利用file-saver保存文件  blogTitle:自定义文件名
          });
        }).finally(() => {
          this.$store.dispatch('loading/setLoadComponent', false)
          this.$forceUpdate()
        })
      },
      getImgArrayBuffer(url) {
        return new Promise((resolve, reject) => {
          //通过请求获取文件blob格式
          let xmlhttp = new XMLHttpRequest();
          xmlhttp.open("GET", url, true);
          xmlhttp.responseType = "blob";
          xmlhttp.onload = function () {
            if (xmlhttp.status == 200) {
              resolve(xmlhttp.response);
            } else {
              reject(xmlhttp.response);
            }
          };
          xmlhttp.send();
        });
      },
      downloadFiles() {
        let params = {
          fns: [],
          id: this.booking.id,
          filetype: 'bf',
        }
        let fileList = []
        this.booking.bookingFiles.filter(item => item.checked).forEach(item => {
          params.fns.push(item.FileName)
          fileList.push({
            fileName: item.FileName
          })
        })
        this.$store.dispatch('loading/setLoadComponent', true)
        utilities.getDownloadFilesUrlNew(params).then(res => {
          const arr = res.replaceAll(']', '').replaceAll('[', '').split(',')
          arr.forEach((item, index) => {
            fileList[index].filePath = item
          })
          this.downloadBtn(fileList)

        }).catch(() => {
          this.$store.dispatch('loading/setLoadComponent', false)
        })
      },
      openAddNew() {
        if($(".tab-content").eq(1).is(":visible")) {
          this.openAddFile()
        } else if (this.curTab === 4) {
          this.openTimeModal()
        }
        else this.openAddNoteModal()
      },
      openAddNoteModal() {
        this.checkBookingObjectisUpdated()
        this.$refs.addNoteModal.addNewNote()
        this.$refs.addNoteModal.handleModal()
      },
      openAddFile() {
        $('#assetsFieldHandle').trigger('click')
      },

      deleteVersionFile(file, obj) {
        this.$confirm({
          content: 'Are you sure you want to delete this version?',
          onConfirm: async () => {
            let param = {
              BookingFileID: file.BookingFileID,
              BookingID: file.BookingID,
              FileName: file.FileName
            }
            if(obj) {
              param.VersionIds = [file.VersionId]
            } else {
              param.VersionIds = []
            }
            utilities.deleteBookingFileVersion(param).then(async () => {
              this.versionList.splice(obj.$index, 1)
              this.booking.bookingFiles = await utilities.getBookingFileNew({
                bookingID: this.booking.id,
                PageNum: 1,
                PageSize: 999
              })
            })
          }
        })
      },

      async deleteFile(fileId, name, bookingId) {
        this.$confirm({
          content: 'This will delete this file and its all versions, Are you sure you want to delete it?',
          onConfirm: async () => {
            this.$store.dispatch('loading/setLoadComponent', true)

            let result = await utilities.deleteBookingFileAllVersion({
              BookingID: bookingId,
              BookingFileID: fileId,
              FileName: name
            })
            if (result) {
              this.versionVisible = false
              let index = this.booking.bookingFiles.findIndex(item => item.BookingFileID === fileId)
              this.booking.bookingFiles.splice(index, 1)
            }
            this.$store.dispatch('loading/setLoadComponent', false)
          }
        })
      },

      openFileTypeModal() {
        this.$refs.fileTypeModal.reset()
        this.$refs.fileTypeModal.fileTypes = this.fileTypes
        this.$refs.fileTypeModal.handleModal()
      },

      clearFile() {
        $("#assetsFieldHandle").val(null)
      },

      onChange() {
        this.openFileTypeModal()
      },

      async uploadFile() {
        if(this.fileTypeId > 0) {
          this.$store.dispatch('loading/setLoadComponent', true)
          this.filelist = [...this.$refs.file.files]

          await Promise.all(this.filelist.map(async (x) => {
            if(this.booking.id !== '') {
              // var fileParams = {
              //   FileName: x.name,
              //   MimeType: x.type,
              //   FileStatus: 1,
              //   FileTypeID: this.fileTypeId,
              //   BookingID: this.booking.id,
              //   DateUploaded: window.moment().format('YYYY-MM-DD HH:mm')
              // }
              try {
                const result = await utilities.postPreSign({
                  ft:x.type ? x.type : 'application/octet-stream',
                  fn: x.name,
                  id: this.booking.id,
                  FileStatus: 1,
                  FileTypeID: this.fileTypeId,
                })
                let postData = new FormData()
                for (let key in result.fields) {
                  postData.append(key, result.fields[key]);
                }
                postData.append('file', x)
                const r = await utilities.postImage(result.url, postData)
                if(r) await utilities.postFileRecord(
                  [{
                    BookingID: this.booking.id,
                    DateUploaded:window.moment().format('YYYY-MM-DD HH:mm:ss'),
                    FileName: x.name,
                    FileStatus: 1,
                    FileTypeID: this.fileTypeId,
                    MimeType: x.type ? x.type : 'application/octet-stream',
                    UploadedBy: this.user.id,
                    UploadedByName: this.user.name
                  }]
                )
                this.booking.bookingFiles = await utilities.getBookingFileNew({bookingID: this.booking.id, PageNum: 1, PageSize: 999})
                this.sortParam(this.listParam.SortFlag)
              } catch (error) {
                utilities.showErrorMessage(error.response.data?.message || error.response.data || error)
              }
            }
          }))
          this.$store.dispatch('loading/setLoadComponent', false)
        }
      },
      dragover(event) {
        event.preventDefault()
        // Add some visual fluff to show the user can drop its files
        // if (!event.currentTarget.classList.contains('bg-green-300')) {
        //   event.currentTarget.classList.remove('bg-gray-100')
        //   event.currentTarget.classList.add('bg-green-300')
        // }
        event.currentTarget.classList.add('dark-border-hover')
      },
      dragleave(event) {
        // Clean up
        // event.currentTarget.classList.add('bg-gray-100')
        // event.currentTarget.classList.remove('bg-green-300')
        event.currentTarget.classList.remove('dark-border-hover')
      },
      drop(event) {
        event.preventDefault()
        this.$refs.file.files = event.dataTransfer.files
        this.onChange() // Trigger the onChange event manually
        // Clean up
        event.currentTarget.classList.add('bg-gray-100')
        event.currentTarget.classList.remove('bg-green-300')
        event.currentTarget.classList.remove('dark-border-hover')
      },
      openManageContactModal(e) {
        e.preventDefault()
        e.stopPropagation()
        this.$refs.adminManageContactModal.singleCase = this.singleCase
        this.$refs.adminManageContactModal.booking = this.booking
        this.$refs.adminManageContactModal.handleModal()
      },

      async setBookingNoteAndAction(params) {
        var bookingNoteAndActions = []
        for (var i = 0; i < this.booking.bookingNotes.length; i++) {
          var item = this.booking.bookingNotes[i]
          var noteObject = {
            id: item.id,
            userName: item.userName,
            deleted: item.deleted,
            userId: item.userId,
            dateTime: params && params?.BookingNoteID === item.id ? params?.NoteDateTime : item.noteDateTime,
            note: item.note,
            type: 'note',
            internalOnly: item.internalOnly,
          }
          bookingNoteAndActions.push(noteObject)
        }
        for (var i = 0; i < this.bookingAction.length; i++) {
          var item = this.bookingAction[i]
          var noteObject = {
            id: item.id,
            userName: item.userName,
            userId: item.requestedBy,
            dateTime: item.dateRequested,
            note: item.requestedAction,
            type: 'action',
            internalOnly: false,
          }
          bookingNoteAndActions.push(noteObject)
        }
        bookingNoteAndActions.sort((a,b) => {
          return moment(a.dateTime, "YYYY-MM-DD HH:mm:ss.ssssss").isBefore(moment(b.dateTime, "YYYY-MM-DD HH:mm:ss.ssssss")) ? 1 : -1
        })
        this.bookingNoteAndActions = bookingNoteAndActions
      },

      async setBillingNoteAndAction(params) {
        var billingNoteAndActions = []
        for (let i = 0; i < this.booking.billingNotes.length; i++) {
          var item = this.booking.billingNotes[i]
          var noteObject = {
            id: item.id,
            userName: item.userName,
            deleted: item.deleted,
            userId: item.userId,
            dateTime: params && params?.BookingNoteID === item.id ? params?.NoteDateTime : item.noteDateTime,
            note: item.note,
            type: 'note',
          }
          billingNoteAndActions.push(noteObject)
        }
        billingNoteAndActions.sort((a,b) => {
          return moment(a.dateTime, "YYYY-MM-DD HH:mm:ss.ssssss").isBefore(moment(b.dateTime, "YYYY-MM-DD HH:mm:ss.ssssss")) ? 1 : -1
        })
        this.billingNoteAndActions = billingNoteAndActions
      },

      async getTimelineData(index) {
        this.timelineData = []
        if (index == 0) {
          var x = null
          var length = this.bookingLogs.length > 10 ? 10 : this.bookingLogs.length
          for (var i = 0; i < length; i++) {
            x = this.bookingLogs[i]
            var timeline = {
              id: x.bookingLogId,
              userName: x.userName,
              datetime: utilities.formatDate(x.logDate, "DD/MM/YYYY hh:mm A", "YYYY-MM-DD HH:mm:ss.ssssss"),
              body: x.logDetails.replace(/set to - /g, ' : '),
            }
            this.timelineData.push(timeline)
          }
        } else {
          var timeline = {}
          this.bookingLogs.map((x) => {
            timeline = {
              id: x.bookingLogId,
              userName: x.userName,
              datetime: utilities.formatDate(x.logDate, 'DD/MM/YYYY hh:mm A', "YYYY-MM-DD HH:mm:ss.ssssss"),
              body: x.logDetails.replace(/set to - /g, ' : '),
            }
            this.timelineData.push(timeline)
          })
        }
      },
      async updateFileType(bookingFileId, index, newName) {
        this.$store.dispatch('loading/setLoadComponent', true)
        var file = this.booking.bookingFiles[index]
        this.booking.bookingFiles.map((x) => {
          if (x.BookingFileID === bookingFileId) {
            x.FileTypeName = this.fileTypes.find((y) => y.id == x.FileTypeID).name
          }
        })
        var params = {
          BookingFileID: bookingFileId,
          FileTypeID: this.booking.bookingFiles.find((x) => x.BookingFileID === bookingFileId).FileTypeID,
          UserID: this.user.id,
          Username: this.user.name,
          BookingID: this.booking.id,
          FileTypeName: this.fileTypes.find((x) => x.id === file.FileTypeID).name,
          FileName: newName ? this.newFileName : file.FileName,
          BookingDateTime:this.booking.bookingDateTime,
          SpecialistName:this.booking.specialistFullName,
          CaseOwnerName:this.getCaseOwnerName,
          ClientName:this.booking.clientName + ' - ' + this.booking.branchName,
          BookingTypeName:this.booking.bookingTypeName,
          LocationName:this.getLocationName(this.booking.locationId),
          CurrentDateTime:window.moment().format('YYYY-MM-DD HH:mm:ss'),
          IsAdmin:true,
        }
        await utilities.putBookingFileDetails(params)
        this.sortParam(this.listParam.SortFlag)
        this.getOriginalBooking()
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      async finalizeFile(bookingFileId, index) {
        var file = this.booking.bookingFiles[index]
        this.$store.dispatch('loading/setLoadComponent', true)
        let statusName = ''
        if(file.FileStatus == 2) {
          statusName = 'Complete'
        } else if (file.FileStatus == 3) {
          statusName = 'For Review'
        } else {
          statusName = this.fileStatus.find((x) => x.id == file.FileStatus).name
        }
        var params = {
          BookingFileID: bookingFileId,
          FileStatus: file.FileStatus,
          UserID: this.user.id,
          Username: this.user.name,
          BookingID: this.booking.id,
          FileStatusName: statusName,
          FileName: file.FileName,
          BookingDateTime:this.booking.bookingDateTime,
          SpecialistName:this.booking.specialistFullName,
          CaseOwnerName:this.getCaseOwnerName,
          ClientName:this.booking.clientName + ' - ' + this.booking.branchName,
          BookingTypeName:this.booking.bookingTypeName,
          LocationName:this.getLocationName(this.booking.locationId),
          CurrentDateTime: window.moment().format('YYYY-MM-DD HH:mm:ss'),
          IsAdmin:true,
        }
        await utilities.putBookingFileDetails(params)
        this.sortParam(this.listParam.SortFlag)
        this.getOriginalBooking()
        this.$store.dispatch('loading/setLoadComponent', false)
      },
      formatDate(date, format = "DD/MM/YYYY", currentFormat = "YYYY-MM-DD") {
        if (date != '' && date != '0000-00-00') {
          return utilities.formatDate(date, format, currentFormat)
        }
        return ''
      },
      toggleCallAfterAppointment() {
        if(this.booking.bookingStatus === 6) return
        utilities.disposeTooltip()
        this.booking.callClientAfterAppointment = !this.booking.callClientAfterAppointment
        utilities.initTooltip()
      },
      toggleCanRingClaimant() {
        if(this.booking.bookingStatus === 6) return
        utilities.disposeTooltip()
        this.booking.redHealthMayContactClaimant = !this.booking.redHealthMayContactClaimant
        utilities.initTooltip()
      },
      toggleAttendance() {
        if(this.booking.bookingStatus === 6) return
        this.isChangedAttendance = true
        utilities.disposeTooltip()
        if (this.isDate(this.booking.claimantConfirmed)) {
          this.booking.claimantConfirmed = false
        } else if (this.booking.claimantConfirmed == true) {
          this.booking.claimantConfirmed = false
        } else {
          this.booking.claimantConfirmed = true
        }
        //this.booking.claimantConfirmed = !this.booking.claimantConfirmed
        utilities.initTooltip()
      },
      toggleConsentReceived() {
        if(this.booking.bookingStatus === 6) return
        this.isChangedConsent = true
        utilities.disposeTooltip()
        if (this.isDate(this.booking.claimantConsentReceived)) {
          this.booking.claimantConsentReceived = false
        } else if (this.booking.claimantConsentReceived == true) {
          this.booking.claimantConsentReceived = false
        } else {
          this.booking.claimantConsentReceived = true
        }
        //this.booking.claimantConsentReceived = !this.booking.claimantConsentReceived
        utilities.initTooltip()
      },
      toggleOTFCE() {
        if(this.booking.bookingStatus === 6) return
        // 4915 - Disable OTFCE for FCE Lite and FCE Lite by Video
        if(this.booking.bookingTypeName == 'FCE Lite' || this.booking.bookingTypeName == 'FCE Lite by Video') {
          return false
        }
        utilities.disposeTooltip()
        this.booking.otfceRequired = !this.booking.otfceRequired
        utilities.initTooltip()
      },
      toggleVideoLinkTestArranged() {
        if(this.booking.bookingStatus === 6) return
        if (this.booking.videoLinkTestStatus == 2) {
          return
        }
        utilities.disposeTooltip()
        if (this.booking.videoLinkTestStatus == '') {
          this.booking.videoLinkTestStatus = 1
        } else {
          this.booking.videoLinkTestStatus = ''
        }
        utilities.initTooltip()
      },
      toggleHasVideo() {
        if(this.booking.bookingStatus === 6) return
        utilities.disposeTooltip()
        if (this.booking.videoLinkTestStatus == 2) {
          this.booking.videoLinkTestStatus = 1
        } else if (true) {
          this.booking.videoLinkTestStatus = 2
        }
        utilities.initTooltip()
      },
      toggleFeeDeferralRequired() {
        if(this.booking.bookingStatus === 6) return
        utilities.disposeTooltip()
        this.booking.feeDeferralRequired = !this.booking.feeDeferralRequired
        utilities.initTooltip()
      },
      togglePrePaymentRequired() {
        if(this.booking.bookingStatus === 6) return
        utilities.disposeTooltip()
        this.booking.prepaymentRequired = !this.booking.prepaymentRequired
        utilities.initTooltip()
      },
      toggleSpecialistConfirmedHours() {
        if(this.booking.bookingStatus === 6) return
        utilities.disposeTooltip()
        this.booking.specialistConfirmedHours = !this.booking.specialistConfirmedHours
        utilities.initTooltip()
      },
      togglelolReceived() {
        if(this.booking.bookingStatus === 6) return
        this.isLolChanged = true
        if (!this.isDate(this.booking.lolReceived)) {
          this.booking.lolReceived = moment().format('YYYY-MM-DD HH:mm')
        } else {
          this.booking.lolReceived = ''
        }
      },
      togglelolBrirgReceived() {
        if(this.booking.bookingStatus === 6) return
        this.isBrirgChanged = true
        if (!this.isDate(this.booking.briefReceived)) {
          this.booking.briefReceived = moment().format('YYYY-MM-DD HH:mm')
        } else {
          this.booking.briefReceived = ''
        }
      },
      togglelolAdditionalReceived() {
        if(this.booking.bookingStatus === 6) return
        this.isAdditionalChanged = true
        if (!this.isDate(this.booking.additionalPaperworkReceived)) {
          this.booking.additionalPaperworkReceived = moment().format('YYYY-MM-DD HH:mm')
        } else {
          this.booking.additionalPaperworkReceived = ''
        }
      },
      togglelolPaperworkProcessed() {
        if(this.booking.bookingStatus === 6) return
        this.isPaperworkChanged = true
        if (!this.isDate(this.booking.paperworkProcessed)) {
          this.booking.paperworkProcessed = moment().format('YYYY-MM-DD HH:mm')
        } else {
          this.booking.paperworkProcessed = ''
        }
      },
      openServiceTimeModalPreApproved() {
        if(this.booking.bookingStatus === 6) return
        this.checkBookingObjectisUpdated()
        this.$refs.adminAddServiceTimeModal.setPreApproved()
        this.$refs.adminAddServiceTimeModal.handleModal()
      },
      openServiceTimeModal() {
        if(this.booking.bookingStatus === 6) return
        this.checkBookingObjectisUpdated()
        this.roleProps = 'adminAsSpecialist'
        this.$refs.adminAddServiceTimeModal.isPreApproved = false
        this.$refs.adminAddServiceTimeModal.handleModal()
      },
      async deleteNote(item, index) {
        if (item.userId !== this.user.id) {
          return
        }
        utilities.showAlert('.confirmation', false)
        this.deleteId = item.id
        this.deleteIndex = index
      },
      async tapButtonConfirmation(states) {
        utilities.hideAlert('.confirmation')
        if (states) {
          this.$store.dispatch('loading/setLoadComponent', true)
          let params = {
            UserID: this.user.id,
            Username: this.user.name,
            id: this.deleteId,
            IsMainEdit: 0,
          }
          if (params.IsMainEdit === 0) {
            params.BookingDateTime = this.booking.bookingDateTime
            params.SpecialistName = this.booking.specialistFullName
            params.CaseOwnerName = this.getCaseOwnerName
            params.ClientName = this.booking.clientName + ' - ' + this.booking.branchName
            params.BookingTypeName = this.booking.bookingTypeName
            params.LocationName = this.getLocationName(this.booking.locationId)
            params.IsAdmin = true
            params.BookingID = this.booking.id
            params.NoteDateTime = moment().format('YYYY-MM-DD HH:mm:ss')
          }
          if(this.isBilling) {
            const result = await utilities.deleteBillingNoteById(params)
            if (result) {
              let note = this.billingNoteAndActions[this.deleteIndex]
              note.deleted = true
              let deleteNote = this.booking.billingNotes.find((x) => x.id === note.id)
              deleteNote.deleted = true
            }
            this.$store.dispatch('loading/setLoadComponent', false)
          } else {
            const result = await utilities.deleteBookingNoteById(params)
            if (result) {
              let note = this.bookingNoteAndActions[this.deleteIndex]
              note.deleted = true
              let deleteNote = this.booking.bookingNotes.find((x) => x.id === note.id)
              deleteNote.deleted = true
            }
            this.$store.dispatch('loading/setLoadComponent', false)
          }
        }
      },

      openTestsModal(e) {
        this.checkBookingObjectisUpdated()
        e.preventDefault()
        e.stopPropagation()
        this.$refs.addSpecialistBookingTestModal.test = this.test
        this.$refs.addSpecialistBookingTestModal.handleModal()
      },

      setTestAndOpenModal(e, test) {
        this.checkBookingObjectisUpdated()
        e.preventDefault()
        e.stopPropagation()
        this.$refs.addSpecialistBookingTestModal.test = test
        this.$refs.addSpecialistBookingTestModal.setBookingTest(test)
        this.$refs.addSpecialistBookingTestModal.handleModal()
      },

      checkInterpreterRequired() {
        if (this.booking.interpreterDetails.trim() != '') this.booking.interpreterRequired = true
        else this.booking.interpreterRequired = false
      },
      checkSurrogateRequired() {
        if (this.booking.surrogateDetails.trim() != '') this.booking.surrogateRequired = true
        else this.booking.surrogateRequired = false
      },

      showWarning() {
        $('html, body').animate({
          scrollTo: $(".button-action").offset()
        }, 500);
        utilities.showAlert('.alert-warning-confirm', false)
      },

      getLocationName(id) {
        if (id && this.locations && this.locations.length > 0) {
          return this.locations.find((x) => x.id == id).fullLocation.replace(', Australia', "")
        }
        return ''
      },

      getLocationState(id) {
        if (id) {
          return this.locations.find((x) => x.id == id).state
        }
      },

      async updateBooking(reason) {
        if ((!this.enableButton || !this.hasChange)) {
          return false
        }

        if (!this.validateState || !this.validateBookingTime) {
          this.hasError = true
          this.errorMessage = "Please correct the errors shown above and try again."
          utilities.showAlert(".general-alert-danger")
          return false
        }

        if (this.showError || (this.isBookingDateChanged && !this.isTimeChanged)) {
          this.errorMessage = 'The selected date and time is no longer available.'
          this.showWarning()
          this.booking.bookingStatus = this.originalStatus
          return false
        }

        if (!this.validPaperworkDue() || !this.validateBillEmail || !this.validateSendEmail) {
          this.hasError = true
          this.errorMessage = "Please correct the errors shown above and try again."
          utilities.showAlert('.alert-warning-no-confirm')
          return false
        }

        this.$store.dispatch('loading/setLoadComponent', true)

        var otherBodyArea = ""
        this.otherBodyAreaTags.map((x) => {
          if (otherBodyArea == "") {
            otherBodyArea = x.text
          } else {
            otherBodyArea = otherBodyArea + "," + x.text
          }
        })
        let id = this.$route.query.id != undefined ? this.$route.query.id : ''
        let curBooking = await utilities.getEditBookingById(id)
        curBooking = curBooking.booking
        if(curBooking.UpdatedTime !== this.booking.UpdatedTime) {
          this.$alert('The booking information has been updated by another user. The page will be refreshed, and you can make your change again.', 'Warning', {
            confirmButtonText: 'OK',
            callback: () => {
              window.location.reload()
            }
          });
          this.$store.dispatch('loading/setLoadComponent', false)
          return
        }

        // Validate
        var bookingTypeName = this.bookingTypes.find((x) => x.id == this.booking.bookingTypeId).name
        // Create request body
        var params = {
          AssignedTo: this.booking.assignedTo,
          CaseID: this.booking.caseId,
          Private: this.booking.private,
          ClientID: this.booking.clientId,
          BookingID: this.booking.id,
          BillTo: this.booking.billTo,
          ReportTo: this.booking.reportTo,
          OrganizeRequired: this.booking.OrganizeRequired,
          // ReportToEmailAddress: this.booking.reportToEmailAddress, //Now billTo and reportTo field passing text
          LocationID: this.booking.locationId,
          CaseReference: this.booking.caseReference,
          PreferredGivenName: this.booking.PreferredGivenName,
          Salutation: this.booking.Salutation,
          LocationName: this.getLocationName(this.booking.locationId),
          StateName: this.getLocationState(this.booking.locationId),
          OnBehalfOf: this.booking.onBehalfOf,
          ClaimTypeID: this.booking.claimTypeId,
          DateofBirth: moment(this.booking.dateOfBirth, "YYYY-MM-DD").format('YYYY-MM-DD'),
          SpecialistID: this.booking.specialistId,
          BookingStatus: this.booking.bookingStatus,
          BookingStatusName: utilities.getBookingStatuses().find((x) => x.id === this.booking.bookingStatus)?.name,
          BookingTypeID: this.booking.bookingTypeId,
          BookingTypeName: bookingTypeName,
          CurrentDateTime: utilities.timeToBrisbane(moment().format('YYYY-MM-DD HH:mm:ss')),
          OTFCERequired: this.booking.otfceRequired,
          BookingDateTime: moment(this.booking.bookingDateTime, "YYYY-MM-DD HH:mm:ss.ssssss").format('YYYY-MM-DD HH:mm:ss'),
          BookingEndTime: window.moment(this.booking.bookingEndTime, 'HH:mm:ss').format('HH:mm:ss'),
          SurrogateDetails: this.booking.surrogateDetails,
          // PaperworkStatus: this.booking.paperworkStatus, // Changed the paperwork status funtion into the new way ticket #4609
          SurrogateRequired: this.booking.surrogateRequired,
          WillAcceptEarlier: this.booking.willAcceptEarlier,
          InterpreterDetails: this.booking.interpreterDetails,
          InterpreterRequired: this.booking.interpreterRequired,
          FeeDeferralRequired: this.booking.feeDeferralRequired,
          VideoLinkTestStatus: this.booking.videoLinkTestStatus,
          BookingTrackingStatus: this.booking.bookingTrackingStatus,
          SpecialistConfirmedHours: this.booking.specialistConfirmedHours,
          CallClientAfterAppointment: this.booking.callClientAfterAppointment,
          RedHealthMayContactClaimant: this.booking.redHealthMayContactClaimant,
          ZohoCaseID: this.singleCase.zohoCaseId,
          SpecialistZohoLink: this.specialists.find((x) => x.id == this.booking.specialistId).zohoLink,
          ZohoDateCreated: moment().format(),
          ZohoBookingDateTime: moment(this.bookingDate + ' ' + this.bookingTime, "YYYY-MM-DD hh:mm A").format(),
          ClientZohoLink: this.client.zohoLink,
          UserID: this.user.id,
          Username: this.user.name,
          ClaimantLocation: this.booking.ClaimantLocation,
          ZohoLink: this.booking.zohoLink.split('/')[this.booking.zohoLink.split('/').length - 1],
          AccidentDate: this.booking.accidentDate != '' ? moment(this.booking.accidentDate, "YYYY-MM-DD").format('YYYY-MM-DD') : "",
          OverPeriodofTime: utilities.convertNumToBool(this.booking.overPeriodofTime),
          BodyAreaOther: otherBodyArea,
          BookingDateTimeChanged: 0,
          BookingStatusChanged: 0,
          WaitlistToBooked: 0,
          Specialty: this.specialist.qualificationList,
        }

        if(reason) {
          params.CancellationReason = reason
        }

        if (this.isLolChanged) {
          params.LOIReceived = this.booking.lolReceived == '' ? '0000-00-00' : this.booking.lolReceived
        }
        if (this.isBrirgChanged) {
          params.BriefReceived = this.booking.briefReceived == '' ? '0000-00-00' :this.booking.briefReceived
        }
        if (this.isAdditionalChanged) {
          params.APaperworkReceived = this.booking.additionalPaperworkReceived == '' ? '0000-00-00' : this.booking.additionalPaperworkReceived
        }
        if (this.isPaperworkChanged) {
          params.PaperworkProcessed = this.booking.paperworkProcessed == '' ? '0000-00-00' : this.booking.paperworkProcessed
        }

        if (this.isChangedAttendance) {
          params.ClaimantConfirmed = this.booking.claimantConfirmed ? moment().format('YYYY-MM-DD HH:mm:ss') : '0000-00-00'
        }

        if (this.isChangedConsent) {
          params.ClaimantConsentReceived = this.booking.claimantConsentReceived ? moment().format('YYYY-MM-DD HH:mm:ss') : '0000-00-00'
        }

        if (this.isTimeChanged) {
          params.BookingEndTime = this.calculateBookingEndTime()
        }

        if (this.bookingOldDateTime != params.BookingDateTime) {
          params.BookingDateTimeChanged = 1
          params.OldBookingDateTime = this.bookingOldDateTime
        }

        if (this.originalStatus != this.booking.bookingStatus) {
          params.BookingStatusChanged = 1
        }

        if (this.originalStatus == 16 && this.booking.bookingStatus == 3) {
          params.WaitlistToBooked = 1
        }

        if (this.booking.paperworkDue) {
          params.PaperworkDue = moment(this.booking.paperworkDue, "YYYY-MM-DD").format('YYYY-MM-DD')
        } else {
          params.PaperworkDue = ''
        }

        // 4202 - Appointments by Video – Handling Location & Links
        params.VideoLinkURL = (this.booking.bookingTypeId == 4 || this.booking.bookingTypeId == 15) ? this.booking.videoLinkUrl : ""
        if(params.VideoLinkURL) {
          params.VideoLink = 1
          this.booking.hasVideoLink = 1
        }
        if(this.booking.bookingTypeId !== 4 && this.booking.bookingTypeId !== 15) {
          params.VideoLink = 0
          this.booking.hasVideoLink = 0
        }
        var location = this.locations.find((x) => x.id == this.booking.locationId)
        var state = location ? location.state : ""
        params.ClaimantStateDuringBooking = (this.booking.bookingTypeId == 4 || this.booking.bookingTypeId == 7 || this.booking.bookingTypeId == 15) ? this.booking.claimantStateDuringBooking : state
        params.ClaimantBookingDateTime = this.booking.claimantBookingDateTime

        // Send this param only if changing booking status from Tentative to something else and zohoLink is empty
        if (this.originalStatus == 2 && this.originalStatus != this.booking.bookingStatus && (this.booking.zohoLink == '' || this.booking.zohoLink === '0')) {
          params.OldBookingStatusTentative = 1
          var contact = this.clientContacts.find((x) => x.userId == this.booking.caseOwner)
          params.ClientContactZohoName = contact != undefined ? contact.fullName : ''
          params.ZohoObject = {
            ClientContactZohoLink: contact != undefined ? contact.zohoClientContactId : '',
            ClaimantSurname: this.booking.claimantSurname,
            ClaimantFirstName: this.booking.claimantFirstName,
            ZohoDateCreated: moment().format(),
          }
        }

        // Pass extra data into the zoho
        params.ClientCategory = this.client.clientCategory
        params.ClientState = this.client.state
        params.CCEmail = this.getCaseOwnerEmail
        params.AvailabilityID = this.booking.specialistAvailabilityId

        //check booking status is
        let sendEmail = false
        if(this.booking.bookingStatus == 8) {
          params.BookingStatusName = 'Booked'
        }
        if (this.booking.bookingStatus == 7 && this.originalBooking.bookingStatus !== 7) {
          sendEmail = true
        }
        this.isUpdated = false
        var callback = async (id, zohoLink = '') => {
          this.bookingOldDateTime = params.BookingDateTime
          this.updatePersonConditions()
          if (id > 0) {
            if (sendEmail) {
              this.sendEmailAfterFailedToAttend(bookingTypeName)
            }
            if(zohoLink != "") {
              this.booking.zohoLink = zohoLink
            }
            this.originalStatus = _.cloneDeep(this.booking.bookingStatus)
            this.successMessage = 'Booking details updated.'
            utilities.showAlert('.alert-success')
            await this.loadBooking()
            this.originalBooking = _.cloneDeep(this.booking)
            this.checkBookingObjectisUpdated()
            this.getOriginalBooking()
            this.$store.dispatch("setOriginalObject", this.originalBooking)
            this.$store.dispatch("setNewObject", this.originalBooking)
            if (this.booking.bookingStatus == 1) {
              this.hideButton = true
            } else {
              this.hideButton = false
            }
            this.setDefaultStatus()
          } else {
            this.errorMessage = 'An error occurred.'
            utilities.showAlert('.alert-warning-no-confirm')
          }

          this.dialogVisible = false
        }
        if (this.booking.bookingTypeName == "Supplementary by Video" || this.booking.bookingTypeName == "Medical Negligence Request" || this.booking.bookingTypeName == "Supplementary" || this.booking.bookingTypeName == "File Review") {
          params.BookingEndTime = ""
          params.BookingDateTime = ""
          params.LocationID = ""
          params.AvailabilityID = 0
        }

        await utilities.putBooking(params, callback)
        // Process response
        this.getBookingLogs()
        this.$store.dispatch('loading/setLoadComponent', false)
        return true
      },

      sendEmailAfterFailedToAttend(bookingTypeName) {
        var specialist = this.specialists.find((x) => x.id == this.booking.specialistId)
        var params = {
          BookingID: this.booking.id,
          CaseOwner: this.booking.caseOwner,
          CaseReference: this.booking.caseReference,
          ClaimantFirstName: this.booking.claimantFirstName,
          ClaimantGivenNames: this.booking.claimantGivenNames,
          ClaimantSurname: this.booking.claimantSurname,
          BookingTypeName: bookingTypeName,
          SpecialistTitle: specialist.title,
          SpecialistFirstName: specialist.firstName,
          SpecialistSurname: specialist.lastName,
          BookingDateTime: moment(this.bookingDate + ' ' + this.bookingTime, "YYYY-MM-DD hh:mm A").format('DD/MM/YYYY hh:mmA'),
          LocationName: this.booking.locationName,
          UserID: this.user.id,
          Username: this.user.name
        }
        utilities.postSendEmailAfterFailedToAttend(params)
      },

      async getBookingLogs(data) {
        //await utilities.getBookingLogsById(this.booking.id)
        //this.bookingLogs = bookingLogs.filter((x) => x.userId == this.user.id)
        if (data != undefined) {
          this.bookingLogs = data
          this.bookingLogs.sort((a, b) => {
            return moment(a.logDate, "YYYY-MM-DD HH:mm:ss.ssssss").isAfter(moment(b.logDate, "YYYY-MM-DD HH:mm:ss.ssssss")) ? -1 : 1
          })
        }
      },
      goToCase(caseId) {
        this.$router.push({ path: '/admin-manage-case', query: { id: caseId } }).catch((err) => {})
      },

      goToPurchaseOrder() {
        this.$router.push({ path: '/admin-manage-purchase-order', query: { id: this.booking.id } }).catch((err) => {})
      },

      openPreEmailModal() {
        var mode = !this.booking.redHealthMayContactClaimant || !this.client.redHealthMayContactClaimants ? 'client' : 'claimant'
        var emailOrSmsLocal = this.booking.claimantMobile != '' ? 1 : 0
        this.openEmailModal(mode, emailOrSmsLocal)
      },

      async openEmailModal(mode, emailOrSmsLocal = 0) {
        if(mode === 'claimant' && (!this.booking.redHealthMayContactClaimant || !this.client.redHealthMayContactClaimants)) {
          return false
        }

        this.$store.dispatch('loading/setLoadComponent', true)
        if(!this.booking.redHealthMayContactClaimant || !this.client.redHealthMayContactClaimants) {
          this.$refs.adminSendEmailModal.redHealthMayContactClaimant = false
        }

        if (emailOrSmsLocal == 0 && this.emails.claimant == '') {
          mode = 'client'
        }
        this.sendToProps = mode
        this.emailOrSms = emailOrSmsLocal

        this.$refs.adminSendEmailModal.email = utilities.initEmail()
        this.$refs.adminSendEmailModal.sendTo = mode
        this.$refs.adminSendEmailModal.files = []
        this.$refs.adminSendEmailModal.radioChange(mode)
        this.$refs.adminSendEmailModal.emailOrSms = this.emailOrSms
        let list = await utilities.getStandardForms()
        list = list.map(item => {
          return {
            name: item,
            type: 'link'
          }
        })
        this.$refs.adminSendEmailModal.standardFiles = this.booking.bookingFiles.map(item => {return {name: item.FileName, type: 'name', uploadTime: item.DateUploaded}}).sort((a, b) => {
          return Date.parse(b.uploadTime) - Date.parse(a.uploadTime)
        }).concat(list)
        this.$refs.adminSendEmailModal.showHideButton()
        this.$refs.adminSendEmailModal.handleModal()
        this.$store.dispatch('loading/setLoadComponent', false)
      },

      async tapDelete() {
        this.$confirm({
          content: 'Are you sure you want to delete this Booking?',
          onConfirm: async () => {
            this.$store.dispatch('loading/setLoadComponent', true)
            var params = {
              userID: this.user.id,
              userName: this.user.name,
              bookingId: this.booking.id,
              caseId: this.booking.caseId
            }
            var result = await utilities.deleteBooking(params)
            if (result) {
              window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
            }
            this.$store.dispatch('loading/setLoadComponent', false)
          }
        })
      },

      requestAction() {
        this.$refs.requestActionModal.handleModal()
      },

      async actionConfirmation(isNew = true) {
        if (isNew) {
          this.successMessage = 'Your update has been saved.'
          utilities.showAlert('.alert-success')
        }
        this.bookingAction = await utilities.getBookingActions(this.booking.id)
        this.setBookingNoteAndAction()
      },
      changeBookingStatusName() {
        this.booking.bookingStatusName = this.bookingStatuses.find((x) => x.id === this.booking.bookingStatus).name
        this.showError = false
      },
      isDate(date) {
        return moment(date, "YYYY-MM-DD").isValid()
      },

      getFileUrl(file) {
        const params = {
          fn: file.FileName,
          id: this.booking.id,
          VersionId: file.VersionId,
          filetype: "bf",
        }
        utilities.getDownloadFileUrlNew(params).then(res => {
          let a = document.createElement('a');
          let fileName = file.fileName
          a.target = '_blank'
          a.download = fileName;
          a.href = res
          document.body.appendChild(a);
          a.click();
          URL.revokeObjectURL(res);
          document.body.removeChild(a);
        })
      },
      // async setImageUrl() {
      //   var temp = this.booking.bookingFiles
      //   if (temp == undefined) {
      //     return
      //   }
      //   await Promise.all(temp.map(async(x) =>{
      //     if (x.fileName != '') {
      //       var params = {
      //         uploadtype: "bf",
      //         filename: x.fileName,
      //         id: this.booking.id
      //       }
      //       x.fileUrl = await utilities.getFileUrl(params)
      //     }
      //   }))
      //   this.booking.bookingFiles = temp
      //   this.fileKey +=1
      // },
      sendSuccess(index) {
        if(index) {
          this.commonParam.PageNum = index
        }
        utilities.getCommonLogs(this.commonParam).then(res => {
          this.commonLogs = res.data
          this.commonsCount = res.total
        })
      },
      async remoteUser(query) {
        this.userParam.UserName = query
        this.loadingUser = true
        var {users} = await utilities.getUsersNew(this.userParam)
        let arr = users.filter(item => item.status)
        for(let i = 0; i < arr.length; i++) {
          if(!this.users.find(item => item.id === arr[i].id)) {
            this.users.unshift(arr[i])
          } else {
            let index = this.users.findIndex(item => item.id === arr[i].id)
            this.users.splice(index, 1)
            this.users.unshift(arr[i])
          }
        }
        this.loadingUser = false
      },
      async loadBooking() {
        var bookingId = this.$route.query.id != undefined ? this.$route.query.id : ''
        this.getSpecialistNotes()
        const apiArr = [utilities.getEditBookingById(bookingId), utilities.getClients(), utilities.getUsersNew(this.userParam), utilities.getCommonLogs(this.commonParam), utilities.getBookingFileNew({bookingID: bookingId, PageNum: 1, PageSize: 999})]
        const res = await Promise.all(apiArr).catch(() => {
          this.$store.dispatch('loading/setLoadComponent', false)
        })
        let bookingData = res[0]
        this.clients = res[1]
        const usersRes = res[2].users
        this.commonLogs = res[3].data
        this.commonsCount = res[3].total
        this.users = usersRes.filter(item => item.status)

        //call booking step function API.
        if(bookingId != '') {

          this.booking = _.cloneDeep(bookingData.booking) //await utilities.getBookingById(bookingId)
          if(this.booking.assignedTo && this.users.filter(user => user.id === this.booking.assignedTo).length === 0) {
            console.log(this.booking)
            this.users.push({id: this.booking.assignedTo, name: decodeURIComponent(this.booking.assignedToName)})
          }
          this.booking.bookingFiles = res[4]
          this.curSpecialty = this.booking.qualification ? this.booking.qualification.join(', ') : ''
          this.sortParam()
          this.specialists = _.cloneDeep(bookingData.specialists)
          this.fileTypes = _.cloneDeep(bookingData.fileTypes)
          const {contacts} = await utilities.getClientContactsNew({ClientID: this.booking.clientId,PageNum:1, SortFlag: 0, PageSize:100, IsActive: 1})
          this.originalClientContacts = _.cloneDeep(utilities.getClientsDropdownAutoCompleteTag(contacts))
          this.listContact = this.originalClientContacts
          //If claimantConfirmed. then save claimantConfirmed as bool value.
          if (this.isDate(this.booking.claimantConfirmed)) {
            this.booking.claimantConfirmed = true
          }

          if (this.isDate(this.booking.claimantConsentReceived)) {
            this.booking.claimantConsentReceived = true
          }

          if (this.booking == undefined) {
            this.showContent = false
            return
          }

          if (this.booking.bookingStatus == 1) {
            this.hideButton = true
          }

          if (this.booking.bookingTypeName == "Supplementary by Video" || this.booking.bookingTypeName == "Medical Negligence Request" || this.booking.bookingTypeName == "Supplementary" || this.booking.bookingTypeName == "File Review") {
            this.isShowForBooking = false
          }

          // 4915 - Set OTFCE is false as default and disable booking types for FCE Lite and FCE Lite by Video
          if (this.booking.bookingTypeName == 'FCE Lite' || this.booking.bookingTypeName == 'FCE Lite by Video') {
            this.booking.otfceRequired = false
            this.isDisabled = true
            this.hidePurchaseOrderButton = true
          } else {
            this.isDisabled = false
            this.hidePurchaseOrderButton = false
          }

          await this.getBookingLogs(bookingData.bookingLog)
          this.getTimelineData(0)


          this.originalStatus = _.cloneDeep(this.booking.bookingStatus)

          if (this.booking.hasVideoLink == 1) {
            this.showSurrogate = false
          }
          // if (this.booking.reportToEmailAddress && this.booking.reportTo == '') {
          //   this.booking.reportTo = -1
          //   this.showOther = true
          // }
          this.bookingDate = this.booking.bookingDateTime != "" ? moment(this.booking.bookingDateTime, "YYYY-MM-DD HH:mm:ss.ssssss").format('YYYY-MM-DD') : ""
          this.bookingTime = this.booking.bookingDateTime != "" ? moment(this.booking.bookingDateTime, "YYYY-MM-DD HH:mm:ss.ssssss").format('hh:mm A') : ""
          this.bookingOldDateTime = this.booking.bookingDateTime != "" ? _.cloneDeep(moment(this.booking.bookingDateTime, "YYYY-MM-DD HH:mm:ss.ssssss").format('YYYY-MM-DD HH:mm:ss')) : ""

          this.client = bookingData.client //await utilities.getClientById(this.booking.clientId)
          this.singleCase = bookingData.case  //await utilities.getCaseByCaseId(this.booking.caseId)
          this.clientContacts = bookingData.client.contacts //await utilities.getClientContacts(this.booking.clientId)

          //set send report to data
          if (bookingData.booking.reportTo.length > 0) {
            let sendReportTo = this.listContact.find((x) => x.value.toLowerCase().includes(bookingData.booking.reportTo.toLowerCase()))
            if (sendReportTo === undefined && this.booking.reportTo) {
              sendReportTo = {
                id: -1,
                name: "Other",
                value: 'Other'
              }
              this.sendToModel = sendReportTo
              this.showSendReportToOther = true
            } else {
              this.sendToModel = sendReportTo
            }
          }

          //setBill to email
          if (bookingData.booking.billTo.length > 0) {
            var billTo = this.listContact.find((x) => x.value.toLowerCase().includes(bookingData.booking.billTo.toLowerCase()))
            if (billTo === undefined && this.booking.billTo) {
              billTo = {
                id: -1,
                name: "Other",
                value: 'Other'
              }
              this.billToModel = billTo
              this.showBillToEmail = true
            } else {
              this.billToModel = billTo
            }
          }

          this.bookingAction = bookingData.bookingAction //await utilities.getBookingActions(this.booking.id)
          this.setBookingNoteAndAction()
          this.setBillingNoteAndAction()

          // this.bodyAreasAutocomplete = await utilities.getBodyArea()
          // this.bodyAreasAutocomplete = this.bodyAreasAutocomplete.map((x) => {
          //   return { id: x.id, text: x.bodyArea }
          // })
          // this.conditionsAutocomplete = await utilities.getPersonConditions()
          // this.conditionsAutocomplete = this.conditionsAutocomplete.map((x) => {
          //   return { id: x.id, text: x.name }
          // })

          if (this.booking.bodyAreas.length > 0) {
            // this.addedBodyArea = _.cloneDeep(this.booking.bodyAreas)
            this.bodyAreaTags = utilities.getAutoCompleteBodyAreaTags(this.booking.bodyAreas)
          }

          if (this.booking.bodyAreaOther.length > 0) {
            var items = this.booking.bodyAreaOther.split(',');
            this.otherBodyAreaTags = utilities.getAutoCompleteOtherBodyAreaTags(items)
          }

          if (this.booking.personConditions.length > 0) {
            this.addedConditions = _.cloneDeep(this.booking.personConditions)
            this.personConditionTags = utilities.getAutoCompleteTags(this.booking.personConditions)
          }

          var bodyAreas = bookingData.bodyAres//await utilities.getBodyArea()
          this.bodyAreas = utilities.getAutoCompleteBodyAreaTags(bodyAreas)

          var personConditions = bookingData.personConditions//await utilities.getPersonConditions()
          this.personConditions = utilities.getAutoCompleteTags(personConditions)

          var specialist = this.specialists.find((x) => x.id == this.booking.specialistId)
          var item = specialist.qualifications.find((q) => q.qualificationId == 43)
          if (item || this.booking.bookingTypeID == 4 || this.booking.bookingTypeId == 9) {
            this.showSurrogate = false
          }
          this.emails = {
            specialist: this.specialists.find((x) => x.id == this.booking.specialistId).email,
            client: this.booking.clientContacts != undefined && this.booking.clientContacts[0] != undefined && this.booking.clientContacts[0].NotificationEmailAddress ? this.booking.clientContacts[0].NotificationEmailAddress : "",
            claimant: this.booking.claimantEmail,
          }
          if(this.emails.client === "" && this.booking.clientContacts != undefined && this.booking.clientContacts[0] != undefined && this.booking.clientContacts[0].email) {
            this.emails.client = this.booking.clientContacts[0].email
          }
          this.phones = {
            specialist: this.specialists.find(x=>x.id == this.booking.specialistId).mobile,
            client: this.booking.clientContacts != undefined && this.booking.clientContacts[0] != undefined ? this.booking.clientContacts[0].mobile : "",
            claimant: this.booking.claimantMobile,
            rhadmin: this.user.phone
          }
          this.names = {
            specialist: this.specialists.find((x) => x.id == this.booking.specialistId).fullName,
            client: this.getCaseOwnerName,
            claimant: this.booking.claimantFullName,
          }
          this.specialist = specialist
          this.statusKey += 1

          //get available time slots.

          //set other dateAdded
          // 4915 - Get list of booking type by current booking type
          //removed unwanted booking BookingType

          var filterBookingType = bookingData.bookingTypes.filter(x=>x.id != 1 && x.id != 11)

          if(this.booking.bookingTypeName == 'FCE Lite' || this.booking.bookingTypeName == 'FCE Lite by Video') { // Get all booking types and cannot change
            this.bookingTypes = filterBookingType
          } else {  // Get all booking types except FCE Lite and FCE Lite by Video
            if(this.booking.bookingTypeId === 2 || this.booking.bookingTypeId === 4 || this.booking.bookingTypeId === 5 || this.booking.bookingTypeId === 7 ) {
              const type = filterBookingType.find(item => item.id === this.booking.bookingTypeId)
              const arr = filterBookingType.filter(item => (item.id === 5 || item.id === 7))
              if(this.booking.videoLinkAvailable && this.specialist.serviceIMEVideo && this.booking.bookingTypeId !== 4) arr.unshift(filterBookingType.find(item => item.id === 4))
              if(this.booking.videoLinkAvailable && this.specialist.serviceIME && this.booking.bookingTypeId !== 2) arr.unshift(filterBookingType.find(item => item.id === 2))
              if(!this.booking.videoLinkAvailable && this.booking.VideoOnly && this.specialist.serviceIMEVideo && this.booking.bookingTypeId !== 4) arr.unshift(filterBookingType.find(item => item.id === 4))
              if(!this.booking.videoLinkAvailable && !this.booking.VideoOnly && this.specialist.serviceIME && this.booking.bookingTypeId !== 2) arr.unshift(filterBookingType.find(item => item.id === 2))
              arr.unshift(type)
              this.bookingTypes = arr
              this.disableBookingType = false
            } else {
              this.bookingTypes = filterBookingType
            }
          }

          this.claimTypes = bookingData.claimTypes
          this.locations = bookingData.locations
          if (this.booking.bookingStatus == 16) {
            this.bookingTime = ""
          }

          //show hide SupplementaryService button depend on the service type
          if (this.booking.originaleBookingId == '' && (this.booking.bookingTypeId == 2 || this.booking.bookingTypeId == 3 || this.booking.bookingTypeId == 4 || this.booking.bookingTypeId == 9)) {
            this.showSupplementaryService = true
          }

          if (this.booking.originaleBookingId != '' && (this.booking.bookingTypeId == 14 || this.booking.bookingTypeId == 15)) {
            this.showClientNotification = false
          }
        }
        this.checkBookingType()
        this.$store.dispatch("setOriginalObject", this.booking)
        this.$store.dispatch("setNewObject", this.booking)
      },
      async loadData() {
        // this.bookingTypes = await utilities.getBookingTypes()
        // this.claimTypes = await utilities.getClaimTypes()
         this.paperworkStatuses = await utilities.getPaperworkStatuses()
        // this.locations = await utilities.getLocations()
        //this.specialists = await utilities.getSpecialists()
        this.states = utilities.getStates()
      },
    },
    async beforeMount() {
      this.$store.dispatch('loading/setLoadComponent', true)
      $('body>.tooltip').remove()
      await this.loadData()
      await this.loadBooking()
      this.getOriginalBooking()
      this.loaded = true
      $('.report-to').click(() => {
        this.focusDropdown = true
      })
      this.openTab(0)
      utilities.initTooltip()
      this.isValidPaperworkDue = this.validPaperworkDue()
      this.$store.dispatch('loading/setLoadComponent', false)
    },
  }
</script>

<style scoped>
.content {
  height: calc(100vh - 76px);
  overflow-y: hidden;
}
.content-sub-content {
  height: 100%;
  overflow-y: auto;
}
  ::v-deep .cancel-dialog .el-dialog__headerbtn .el-dialog__close {
      font-size: 24px;
      font-weight: bold;
    }
  ::v-deep .el-dialog__headerbtn:hover .el-dialog__close{
    color: #909399;
  }
  .cancel-dialog .v-btn {
    min-width: 120px !important;
    min-height: 39px;
    border-radius: 5px;
    text-transform: capitalize;
    background-color: #525252;
    box-shadow: none;
  }
  .cancel-dialog .white-button {
    background-color: unset !important;
    color: #3FA2F7;
  }

</style>
